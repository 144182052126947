import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import S from './simp-banner.module.scss';
import { SuccessIcon } from '../../../images/success-icon';
import { CancelIcon } from '../../../images/cancel-icon';

const SimpBanner = ({type, className, text}) => {
  const IS_SUCCESS = type === 'SUCCESS';
  return (
    <div className={ClassNames(S.banner, className, {
      [S.success]: IS_SUCCESS,
      [S.fail]: !IS_SUCCESS
    })}>
      { IS_SUCCESS && <SuccessIcon />}
      {!IS_SUCCESS && <CancelIcon />}
      <p>{text}</p>
    </div>
  );
};

SimpBanner.displayName = 'Banner';
SimpBanner.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['SUCCESS', 'FAIL']).isRequired,
  text: PropTypes.string.isRequired
};

export { SimpBanner };
