import React from 'react';

import S from './qkn-footer.module.scss';
import { LINKS, IMAGES } from '../../helpers/constants/constants';

function QknFooter() {
  return (
    <footer className={S.qknFooter}>
      <div className={S.footerWrapper}>
        <img alt="Quicken logo" src={IMAGES.QKN_LOGO} className={S.logo}/>
        <nav>
          <ul>
            <li><a href={LINKS.FTR_SITEMAP}>Sitemap</a></li>
            <li><a href={LINKS.FTR_LEGAL}>Legal notice</a></li>
            <li><a href={LINKS.QKN_TERMS}>Terms of Use</a></li>
            <li><a href={LINKS.PRIVACY}>Privacy</a></li>
            <li><a href={LINKS.FTR_AGREEMENT}>Membership Agreement</a></li>
            <li><a href={LINKS.FTR_ABOUT}>About Quicken</a></li>
          </ul>
        </nav>

        <p>© 2020 Quicken Inc. All rights reserved. Quicken is a registered trademark of Intuit Inc., used under license.</p>
      </div>
    </footer>
  );
}

QknFooter.displayName = 'QknFooter';

export { QknFooter };
