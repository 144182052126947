import React from 'react';

import S from './simp-success-dashboard.module.scss';
import { getStoreLink, getIsMobile } from '../../../helpers/page-helpers';
import { DownLoadIcon } from '../../../images/download';

function SimpSuccessDashboard() {
  const LINK = getStoreLink();
  const END_TEXT = getIsMobile() ? '' : ' on the web';

  return (
    <article className={S.confirmationBox}>
      <h3>… or visit your Simplifi dashboard{END_TEXT}.</h3>
      <p>Get started with Simplifi by linking your bank accounts.</p>

      <DownLoadIcon/>

      <a href={LINK} className={S.blueBtn}>Set up my Simplifi</a>
    </article>
  );
}

SimpSuccessDashboard.displayName = 'SimpSuccessDashboard';

export { SimpSuccessDashboard };
