import React from 'react';
import { SlideDown } from 'react-slidedown';

import S from './qkn-ar-help.module.scss';
import { IMAGES } from '../../../helpers/constants/constants';

const QknAutoRenewHelp = () => {
  const [showHelp, setShowHelp] = React.useState(false);

  return (
    <>
      {!showHelp && (
        <button
          type="button"
          className={S.showBtn}
          onClick={() => setShowHelp(true)}
        >How does auto-renew work?</button>
      )}

      <SlideDown closed={!showHelp} className={S.qknAutoRenewHelp}>
        <h3 className={S.headingText}>How does auto-renew work?</h3>

        <ul className={S.wrap}>
          <li>
            <img src={IMAGES.CLOCK_ICON} alt="clock"/>
            <p><span>We&apos;ll notify you at least 30 days</span> before we renew your membership</p>
          </li>
          <li>
            <img src={IMAGES.CC_ICON} alt="credit card" />
            <p><span>We&apos;ll charge your card on the last day</span> of your membership</p>
          </li>
          <li>
            <img src={IMAGES.CANCEL_ICON} alt="cancel circle" />
            <p><span>You can cancel this service anytime</span> if you change your mind</p>
          </li>
        </ul>

        <button
          type="button"
          className={S.qknLinkBtn}
          onClick={() => setShowHelp(false)}
        >CLOSE HELP</button>
      </SlideDown>
    </>
  );
};

QknAutoRenewHelp.displayName = 'QknAutoRenewHelp';

export { QknAutoRenewHelp };
