import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SimpUnavailable } from './flow-simplifi/simp-unavailable';

import { GetUserData } from './get-data';
import { LoginCallback } from './login-callback';
import { LogOutComponent } from './logout-component';
import { QuickenLogin } from './flow-quicken/qkn-login';
import { QknProfileFlow } from './flow-quicken/qkn-profile/qkn-profile-ctx';
import { QknSubscribed } from './flow-quicken/qkn-subscribed/qkn-subscribed';
import { QknActivate } from './flow-quicken/qkn-activation/qkn-activate';
import { QknArFlow } from './flow-quicken/qkn-ar/qkn-ar-ctx';
import { QknSuccess } from './flow-quicken/qkn-success/qkn-success';
import { SimpActivationFlow } from './flow-simplifi/simp-activation/simp-activation-ctx';
import { SimpArFlow } from './flow-simplifi/simp-ar/simp-ar-root';
import { SimpSuccessFlow } from './flow-simplifi/simp-success/simp-success-root';
import { SimpSubscribed } from './flow-simplifi/simp-subscribed';
import { SimpLandingPage } from './flow-simplifi/simp-landing';
import { SimpLogin } from './flow-simplifi/simp-login';
import { useGlobalCtx } from '../helpers/context/global-context';
import { ROUTES } from '../helpers/constants/constants';
import { useSetHead } from '../helpers/hooks/use-set-head';
import { initAxios } from '../network/network-services';

const ProtectedRoute = (props) => {
  const PRODUCT = localStorage.getItem('product') || '';
  const IS_SIMP = PRODUCT.toLowerCase() === 'simplifi';
  const {globalState: {meta: {hasData}}} = useGlobalCtx();
  const HAS_TOKEN = !!localStorage.getItem('bearer');

  if (HAS_TOKEN) {
    return hasData // Check to make sure user has been directed through pages and not URL
      ? <Route exact {...props} />
      : <Redirect to={ROUTES.GET_DATA}/>;
  }
  else {
    return <Redirect to={IS_SIMP ? ROUTES.SIMP_LANDING : ROUTES.QKN_LOGIN}/>;
  }
};

const HasToken = (props) => {
  const PRODUCT = localStorage.getItem('product') || '';
  const IS_SIMP = PRODUCT.toLowerCase() === 'simplifi';
  const HAS_TOKEN = !!localStorage.getItem('bearer');

  if (HAS_TOKEN) {
    return <Route exact {...props} />;
  }
  else {
    return <Redirect to={IS_SIMP ? ROUTES.SIMP_LANDING : ROUTES.QKN_LOGIN}/>;
  }
};


const Routes = () => {
  const PRODUCT = localStorage.getItem('product') || '';
  const IS_SIMP = PRODUCT === 'simplifi';

  initAxios(IS_SIMP ? 'simplifi' : 'quicken');
  useSetHead('Simplifi');
  // useSetHead(IS_SIMP ? 'Simplifi' : 'Quicken');

  return (
    <Switch>
      {/* GENERIC ROUTES WITH NO AUTHENTICATION */}
      <Route exact path={ROUTES.LOGIN_CALLBACK} component={LoginCallback}/>
      <Route exact path={ROUTES.LOGOUT} component={LogOutComponent}/>

      {/* PRODUCT ROUTES WITH NO AUTHENTICATION */}
      <Route exact path={ROUTES.QKN_LOGIN} component={QuickenLogin}/>
      <Route exact path={ROUTES.SIMP_LANDING} component={SimpLandingPage}/>
      <Route exact path={ROUTES.SIMP_LOGIN} component={SimpLogin}/>

      {/* CHECK THAT USER HAS BEARER */}
      <HasToken path={ROUTES.GET_DATA} component={GetUserData}/>

      {/* SIMPLIFI AUTHENTICATED ROUTES */}
      <ProtectedRoute path={ROUTES.SIMP_SUBSCRIBED} component={SimpSubscribed}/>
      <ProtectedRoute path={ROUTES.SIMP_UNAVAILABLE} component={SimpUnavailable} />
      <ProtectedRoute path={ROUTES.SIMP_ACTIVATE} component={SimpActivationFlow}/>
      <ProtectedRoute path={ROUTES.SIMP_AUTO_RENEW} component={SimpArFlow}/>
      <ProtectedRoute path={ROUTES.SIMP_SUCCESS} component={SimpSuccessFlow}/>

      {/* QUICKEN AUTHENTICATED ROUTES */}
      <ProtectedRoute path={ROUTES.QKN_SUBSCRIBED} component={QknSubscribed}/>
      <ProtectedRoute path={ROUTES.QKN_ACTIVATE} component={QknActivate}/>
      <ProtectedRoute path={ROUTES.QKN_AUTO_RENEW} component={QknArFlow}/>
      <ProtectedRoute path={ROUTES.QKN_PROFILE} component={QknProfileFlow}/>
      <ProtectedRoute path={ROUTES.QKN_SUCCESS} component={QknSuccess}/>

      {/* FALL BACK ROUTES COULD BE REPLACED WITH 404*/}
      <Route path="/" component={() => <Redirect to={ROUTES.QKN_LOGIN}/>}/>
    </Switch>
  );
};

Routes.displayName = 'Routes';

export { Routes };
