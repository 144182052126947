import React from 'react';

export const CancelIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Group</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-Ineligible-(returning-users)" transform="translate(-466.000000, -124.000000)" stroke="#FF5959" strokeWidth="2">
        <g id="ineligible" transform="translate(467.000000, 124.000000)">
          <g id="Group" transform="translate(0.000000, 1.000000)">
            <circle id="Oval" cx="9" cy="9" r="9"/>
            <line x1="6" y1="6" x2="12.1894492" y2="12.1894492" id="Path-2"/>
            <line x1="6" y1="12.1894492" x2="12.1894492" y2="6" id="Path-2"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
