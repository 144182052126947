import Axios from 'axios';

const BASE_URL = process.env.REACT_APP_QCS_URL;


export const getBearer = () => `Bearer ${localStorage.getItem('bearer')}`;

export const getOAuthToken = (code = '', isSimp = false) =>
  Axios.post(`${BASE_URL}/oauth/token`, {
    code,
    responseType: 'token',
    grantType: 'authorization_code',
    clientId: isSimp
      ? process.env.REACT_APP_SIMP_OAUTH_CLIENT_ID
      : process.env.REACT_APP_QKN_OAUTH_CLIENT_ID,
    clientSecret: isSimp
      ? process.env.REACT_APP_SIMP_OAUTH_SECRET
      : process.env.REACT_APP_QKN_OAUTH_SECRET,
    redirectUri: `${process.env.REACT_APP_SITE_URL}/login`
  });


export const getUserProfile = () =>
  Axios.get(
    `${BASE_URL}/userprofiles/me`,
    {headers: {Authorization: getBearer()}}
  );


export const sendSMS = (phonenumber, message) =>
  Axios.post(
    `${BASE_URL}/notifications/sms/send`,
    {message, phonenumber},
    {headers: {Authorization: getBearer()}}
  );


export const checkActivationCode = (code, userId) =>
  Axios.post(
    `${BASE_URL}/activation/check`,
    {userId, code},
    {headers: {Authorization: getBearer()}}
  );


export const callGetSubscriptions = () =>
  Axios.get(
    `${BASE_URL}/subscriptions`,
    {headers: {Authorization: getBearer()}}
  );


export const callGetPaymentMethods = () =>
  Axios.get(
    `${BASE_URL}/paymentmethods`,
    {headers: {Authorization: getBearer()}}
  );


export const callUpdatePaymentNonce = (nonce = '') =>
  Axios.post(
    `${BASE_URL}/subscriptions/add-payment-method-from-nonce`,
    {paymentMethodNonce: nonce},
    {headers: {Authorization: getBearer()}}
  );


export const callUpdateSurvey = ({subId, channel, experience, retailer}) =>
  Axios.post(
    `${BASE_URL}/subscriptions/action/${subId}`,
    {
      channel,
      retailer,
      prev_quicken_user: experience
    },
    {headers: {Authorization: getBearer()}}
  );


export const callActivateCode = ({code, userId, autoRenew, channel, experience}) =>
  Axios.post(
    `${BASE_URL}/activation/consume`,
    {
      code,
      userId,
      autoRenew,
      metadata: {
        channel,
        prev_quicken_user: experience
      }
    },
    {headers: {Authorization: getBearer()}}
  );


export const callUpdateUserNames = ({userId = '', firstName = '', lastName = ''} = {}) =>
  Axios.put(
    `${BASE_URL}/userprofiles/${userId}`,
    {
      firstName,
      lastName
    },
    {headers: {Authorization: getBearer()}}
  );

export const callGetSimpPrice = (sku = '') =>
  Axios.get(
    'https://www.quicken.com/get_sku_prices',
    {
      params: {sku},
      headers: {}
    });

export const callUpdateAddress = ({
  fullName = '',
  line1 = '',
  line2 = '',
  city = '',
  zipCode = '',
  state = '',
  country = ''
}) =>
  Axios.post(
    `${BASE_URL}/userprofiles/me/addresses`,
    {
      fullName,
      line1,
      line2: line2 || null,
      city,
      country,
      state,
      zipCode,
      primary: true,
      defaultForBilling: true,
      defaultForShipping: false
    },
    {headers: {Authorization: getBearer()}}
  );
