import React from 'react';

import { GLOBAL_CTX } from '../constants/constants';


export const GLOBAL_TYPES = {
  SET_GLOBAL_FIELD: 'SET_GLOBAL_FIELD',
  SET_GLOBAL_FIELDS: 'SET_GLOBAL_FIELDS',
  SET_GLOBAL_PAYMENT_FIELDS: 'SET_GLOBAL_PAYMENT_FIELDS'
};


export const setGlobalField = (field, payload = '') =>
  ({type: GLOBAL_TYPES.SET_GLOBAL_FIELD, field, payload});

export const setGlobalFields = (payload = {}) =>
  ({type: GLOBAL_TYPES.SET_GLOBAL_FIELDS, payload});

export function globalReducer(state, {type, field, payload}) {
  switch (type) {

    case GLOBAL_TYPES.SET_GLOBAL_FIELD:
      return {...state, [field]: payload};

    case GLOBAL_TYPES.SET_GLOBAL_FIELDS:
      return {...state, ...payload};

    default:
      throw Error(`Unsupported global action type: ${type}`);
  }
}

export const useGlobalCtx = () => {
  const context = React.useContext(GLOBAL_CTX);
  if (!context) {
    throw Error(`${useGlobalCtx.name} must be used within a ${globalReducer.name}`);
  }

  const [globalState, globalDispatch] = context;
  return {globalState, globalDispatch};
};
