import React from 'react';
import QS from 'query-string';

import * as S from './simp-login.module.scss';
import { SimpWrapper } from './simp-wrapper';
import { ROUTES } from '../../helpers/constants/constants';

const LOGIN_PARAMS = QS.stringify({
  'hide-logo': true,
  response_type: 'code',
  client_id: process.env.REACT_APP_SIMP_OAUTH_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_SITE_URL}${ROUTES.LOGIN_CALLBACK}`,
});

const IFRAME_URL = `${process.env.REACT_APP_SIGN_IN_URL}/signup?${LOGIN_PARAMS}`;

const SimpLogin = ({history: {push}}) => {

  React.useEffect(() => {
    const HAS_TOKEN = !!localStorage.getItem('bearer');

    if (HAS_TOKEN) {
      push(ROUTES.GET_DATA);
    }
  }, [push]);

  return (
    <SimpWrapper>
      <div className={S.loginPage}>
        <h1>Create an account or log-in to activate your Simplifi subscription.</h1>
        <iframe src={IFRAME_URL} title="Simplifi Login" />
      </div>
    </SimpWrapper>
  );
};

export { SimpLogin };
