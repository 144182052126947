import { YEARLY_REGEX, INTERVAL_REGEX} from './constants/regex';

export const adjustedDate = (plan, dateObj, addDay = false) => {
  plan = plan || 'P0M';
  const INTERVAL = Number(plan.replaceAll(INTERVAL_REGEX, ''));
  const DATE = new Date();

  if (YEARLY_REGEX.test(plan)) {
    DATE.setFullYear(DATE.getFullYear() + INTERVAL);
  }
  else {
    DATE.setMonth(DATE.getMonth() + INTERVAL);
  }

  if (addDay) {
    DATE.setDate(DATE.getDate() + 1);
  }

  return new Date(DATE).toLocaleDateString('en-US', dateObj);
};
