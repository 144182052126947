import React from 'react';

import S from './qkn-auto-renew.module.scss';
import { QknAutoRenewHelp } from './qkn-ar-help';
import { QknAutoRenewForm } from './qkn-ar-form';
import { QknWrapper } from '../qkn-wrapper';
import { IMAGES } from '../../../helpers/constants/constants';


const QknAutoRenew = () => {
  return (
    <QknWrapper>
      <section className={S.autoRenewArea}>
        <article className={S.qknOfferBox}>
          <img src={IMAGES.OFFER_BADGE} alt="free 1 month offer badge"/>
          <div>
            <h1>SPECIAL OFFER: GET ANOTHER MONTH FREE</h1>
            <h2>Enable Auto-Renew and get an extra month added to your Quicken plan</h2>
          </div>
        </article>

        <article className={S.autoRenewBox}>
          <div className={S.recommendation}>
            <p>
              We recommend enabling automatic renewal for peace of mind, knowing you&apos;ll never lose service due to an
              expired membership.
            </p>

            <p>
              Simply enter your payment method and click
              <span>Enable Auto-Renew</span>
            </p>
          </div>

          <QknAutoRenewHelp/>

          <QknAutoRenewForm />

        </article>
      </section>
    </QknWrapper>
  );
};

QknAutoRenew.displayName = 'QknAutoRenew';


export { QknAutoRenew };
