import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { SimpLogoArea } from './common/simp-logo-area';
import S from '../../sass/simp-themes.module.scss';
import * as GlobalCtx from '../../helpers/context/global-context';
import { getFlowData } from '../../helpers/flow-helpers';

const SimpWrapper = ({children, hasDots = false, className}) => {
  const {globalState: {flow}} = GlobalCtx.useGlobalCtx();
  const {src, name} = getFlowData(flow);

  return (
    <main className={ClassNames(S.pageLayout, className, {[S.light]: true})}>
      <div className={S.simpPageWrapper}>
        <SimpLogoArea src={src} name={name}/>
        {children}
      </div>
      {hasDots && (
        <div className={S.canvas}>
          <div />
          <div />
        </div>
      )}
    </main>
  );
};

SimpWrapper.displayName = 'SimpWrapper';
SimpWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  isLight: PropTypes.bool,
};

SimpWrapper.defaultProps = {
  className: null,
  hasDots: false,
};

export { SimpWrapper };
