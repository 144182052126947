import React from 'react';

import { QknSuccessBanner } from './qkn-success-banner';
import S from './qkn-success.module.scss';
import { QknWrapper } from '../qkn-wrapper';
import { IMAGES, LINKS } from '../../../helpers/constants/constants';
import * as GlobalCtx from '../../../helpers/context/global-context';

const QknSuccess = () => {
  const {globalState: {email, product}} = GlobalCtx.useGlobalCtx();

  return (
    <QknWrapper>
      <section className={S.successArea}>
        <h1 className={S.qknH1}>Your activation is complete. Thank you!</h1>

        <div className={S.successBanner}>
          <img src={IMAGES.SUCCESS_ICON} alt="Success checkmark"/>
          <QknSuccessBanner {...product} />
        </div>

        <div className={S.successBox}>
          <h2><span>Congratulations, your copy of Quicken</span><span>has been activated!</span></h2>
          <div className={S.instructions}>
            <p>To get started, simply download/install the product,</p>
            <p>sign in with your Quicken ID, and it will be ready to use.</p>
          </div>

          <a
            href={`https://download.quicken.com/windows/Quicken.exe?email=${email}`}
            className={S.winDownLoad}
          >DOWNLOAD FOR WINDOWS</a>

          <p className={S.macDownLoad}>
            Not on Windows?
            <a
              href={`https://download.quicken.com/mac/Quicken.dmg?email=${email}`}
              className={S.qknLinkBtn}
            >Download for macOS</a>
          </p>
        </div>

        <div className={S.visitArea}>
          <p>Visit
            <a href={LINKS.MY_ACCOUNT} className={S.myAccountLink}>www.quicken.com/my-account</a>
            when you want to access your account, manage your subscription, or download your Quicken software again
          </p>
        </div>

      </section>
    </QknWrapper>
  );
};

QknSuccess.displayName = 'QknSuccess';

export { QknSuccess };
