import React from 'react';

import S from './qkn-header.module.scss';
import { IMAGES } from '../../helpers/constants/constants';

function QknHeader() {
  return (
    <header className={S.qknHeader}>
      <a href="//quicken.com">
        <img src={IMAGES.QKN_LOGO} alt="Quicken logo"/>
      </a>
    </header>
  );
}

QknHeader.displayName = 'QuickenHeader';
export { QknHeader };
