import React from 'react';

import S from './qkn-ar-terms.module.scss';
import { LINKS } from '../../../helpers/constants/constants';

const QknARTerms = () => {
  return (
    <p className={S.termsText}>
      By clicking
      <span>enable auto-renew</span>
      you are agreeing to our
      <a href={LINKS.EULA} className={S.qknLinkBtn} target="_blank" rel="noopener noreferrer">License Agreement</a>,
      <a href={LINKS.PRIVACY} className={S.qknLinkBtn} target="_blank" rel="noopener noreferrer">Privacy Notice</a>,
      and auto-renew service
    </p>
  );
};

QknARTerms.displayName = 'QknARTerms';

export default QknARTerms;
