import React from 'react';
import PropTypes from 'prop-types';

import S from './qkn-ar-form.module.scss';
import { useOutsideClick } from '../../../helpers/hooks/use-outside-click';

const QknCvvHelp = ({onClose}) => {
  const BTN_REF = React.useRef(null);

  useOutsideClick(BTN_REF, onClose);

  return (
    <div ref={BTN_REF} className={S.helpText}>
      A 3 digit security code usually found on the back of your card.
      American Express cards will have a 4 digit code located on the front.
    </div>
  );
};

QknCvvHelp.displayName = 'CvvHelp';
QknCvvHelp.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default QknCvvHelp;
