export const RETAILER_OPTIONS = [
  {value: '', label: 'Make a selection'},
  {value: 'Walmart', label: 'Walmart'},
  {value: 'Target', label: 'Target'},
  {value: 'Office Depot/Max', label: 'Office Depot/Max'},
  {value: 'Staples', label: 'Staples'},
  {value: 'Best Buy', label: 'Best Buy'},
  {value: 'Sams Club', label: 'Sam\'s Club'},
  {value: 'Costco', label: 'Costco'},
  {value: 'BJs', label: 'BJ\'s'},
  {value: 'Frys', label: 'Fry\'s'},
  {value: 'Micro Center', label: 'Micro Center'},
  {value: 'Amazon', label: 'Amazon'},
  {value: 'Newegg', label: 'Newegg'},
  {value: 'Eurpac', label: 'Eurpac'},
  {value: 'CDW', label: 'CDW'},
  {value: 'PCM', label: 'PCM'},
  {value: 'Ebay', label: 'Ebay'},
  {value: 'Other', label: 'Other'}
];

export const NEW_XP_OPTIONS = [
  {value: 'New', label: 'I\'ve never used Quicken before'},
  {value: 'Winback', label: 'I\'ve used Quicken 2016 or earlier'},
  {value: 'Existing', label: 'I\'m using Quicken 2017 - 2019'}
];

export const OLD_XP_OPTIONS = [
  {value: 'New', label: 'Yes'},
  {value: 'WinBack', label: 'No'}
];

export const CA_PROVINCE = [
  {value: 'AB', label: 'Alberta'},
  {value: 'BC', label: 'British Columbia'},
  {value: 'MB', label: 'Manitoba'},
  {value: 'NB', label: 'New Brunswick'},
  {value: 'NF', label: 'Newfoundland and Labrador'},
  {value: 'NS', label: 'Nova Scotia'},
  {value: 'ON', label: 'Ontario'},
  {value: 'PE', label: 'Prince Edward Island'},
  {value: 'QU', label: 'Quebec'},
  {value: 'SK', label: 'Saskatchewan'},
  {value: 'NW', label: 'North West Territories'},
  {value: 'NU', label: 'Nunavut'},
  {value: 'YU', label: 'Yukon'}
];

export const STATE_OPTIONS = [
  {value: 'AL', label: 'Alabama'},
  {value: 'AK', label: 'Alaska'},
  {value: 'AZ', label: 'Arizona'},
  {value: 'AR', label: 'Arkansas'},
  {value: 'CA', label: 'California'},
  {value: 'CO', label: 'Colorado'},
  {value: 'CT', label: 'Connecticut'},
  {value: 'DE', label: 'Delaware'},
  {value: 'FL', label: 'Florida'},
  {value: 'GA', label: 'Georgia'},
  {value: 'HI', label: 'Hawaii'},
  {value: 'ID', label: 'Idaho'},
  {value: 'IL', label: 'Illinois'},
  {value: 'IN', label: 'Indiana'},
  {value: 'IA', label: 'Iowa'},
  {value: 'KS', label: 'Kansas'},
  {value: 'KY', label: 'Kentucky'},
  {value: 'LA', label: 'Louisiana'},
  {value: 'ME', label: 'Maine'},
  {value: 'MD', label: 'Maryland'},
  {value: 'MA', label: 'Massachusetts'},
  {value: 'MI', label: 'Michigan'},
  {value: 'MN', label: 'Minnesota'},
  {value: 'MS', label: 'Mississippi'},
  {value: 'MO', label: 'Missouri'},
  {value: 'MT', label: 'Montana'},
  {value: 'NE', label: 'Nebraska'},
  {value: 'NV', label: 'Nevada'},
  {value: 'NH', label: 'New Hampshire'},
  {value: 'NJ', label: 'New Jersey'},
  {value: 'NM', label: 'New Mexico'},
  {value: 'NY', label: 'New York'},
  {value: 'NC', label: 'North Carolina'},
  {value: 'ND', label: 'North Dakota'},
  {value: 'OH', label: 'Ohio'},
  {value: 'OK', label: 'Oklahoma'},
  {value: 'OR', label: 'Oregon'},
  {value: 'PA', label: 'Pennsylvania'},
  {value: 'RI', label: 'Rhode Island'},
  {value: 'SC', label: 'South Carolina'},
  {value: 'SD', label: 'South Dakota'},
  {value: 'TN', label: 'Tennessee'},
  {value: 'TX', label: 'Texas'},
  {value: 'UT', label: 'Utah'},
  {value: 'VT', label: 'Vermont'},
  {value: 'VA', label: 'Virginia'},
  {value: 'WA', label: 'Washington'},
  {value: 'WV', label: 'West Virginia'},
  {value: 'WI', label: 'Wisconsin'},
  {value: 'WY', label: 'Wyoming'},
  {value: 'DC', label: 'District of Columbia'},
  {value: 'AS', label: 'American Samoa'},
  {value: 'GU', label: 'Guam'},
  {value: 'MP', label: 'Northern Mariana Islands'},
  {value: 'PR', label: 'Puerto Rico'},
  {value: 'UM', label: 'United States Minor Outlying Islands'},
  {value: 'VI', label: 'Virgin Islands, U.S.'}
];

export const MILITARY_OPTIONS = [
  {value: 'AEF', label: 'Armed Forces Africa'},
  {value: 'AEA', label: 'Armed Forces Americas'},
  {value: 'AEC', label: 'Armed Forces Canada'},
  {value: 'AE"', label: 'Armed Forces Europe'},
  {value: 'AEM', label: 'Armed Forces Middle East'},
  {value: 'AP', label: 'Armed Forces Pacific'}
];
