import React from 'react';
import { Link } from 'react-router-dom';

import S from './qkn-subscribed.module.scss';
import { QknWrapper } from '../qkn-wrapper';
import { ROUTES } from '../../../helpers/constants/constants';
import * as GlobalCtx from '../../../helpers/context/global-context';

const QknSubscribed = () => {
  const {globalState: {email}} = GlobalCtx.useGlobalCtx();

  return (
    <QknWrapper>
      <section className={S.subscribedArea}>
        <h1 className={S.qknH1}>Quicken Setup</h1>

        <article className={S.downloadBox}>
          <h2 className={S.qknH2}>You have an active subscription with Quicken</h2>
          <p>Continue to download and install the product.</p>
          <p>Sign in with your Quicken ID, and it will be ready to use.</p>
          <a
            href={`https://download.quicken.com/windows/Quicken.exe?email=${email}`}
            className={S.winDownLoad}
          >DOWNLOAD FOR WINDOWS</a>

          <p className={S.macDownLoad}>
            Not on Windows?
            <a
              href={`https://download.quicken.com/mac/Quicken.dmg?email=${email}`}
              className={S.qknLinkBtn}
            >Download for macOS</a>
          </p>
        </article>

        <article className={S.purchaseBox}>
          <h2 className={S.qknH2}>Purchased a new copy of Quicken?</h2>
          <Link to={ROUTES.QKN_ACTIVATE} className={S.productBtn}>ENTER A NEW PRODUCT KEY</Link>
        </article>

      </section>
    </QknWrapper>
  );
};

QknSubscribed.displayName = 'QknSubscribed';

export { QknSubscribed };
