import React from 'react';

import S from './simp-success.module.scss';
import { SimpSuccessDashboard } from './simp-success-dashboard';
import { SimpMobile } from './simp-success-mobile';
import { SimpDownloadBox } from './simp-success-download';
import { SimpWrapper } from '../simp-wrapper';
import { SimpBanner } from '../common/simp-banner';
import * as GlobalCtx from '../../../helpers/context/global-context';
import { getFlowData } from '../../../helpers/flow-helpers';
import { getIsMobile } from '../../../helpers/page-helpers';


function SimpSuccessPage() {
  const {globalState: {flow, simpSuccessObj}} = GlobalCtx.useGlobalCtx();
  const {name, type} = getFlowData(flow);
  const {lastDay, description} = simpSuccessObj;
  const IS_MOBILE = getIsMobile();

  return (
    <SimpWrapper hasDots>
      <section className={S.confirmArea}>
        <SimpBanner type="SUCCESS" text="Subscription activated!" className={S.banner}/>
        <h1 className={S.successHeading}>
          {type === 'EDU'
            ? `From ${name} to Simplifi, you’re making the best choices for your future.`
            : 'Success! Your Simplifi trial is ready to go!'
          }
        </h1>

        <h2>Your subscription is now active until <span>{lastDay}.</span></h2>

        <div className={S.descriptionArea}>{description}</div>

        <div className={S.offerArea}>
          { IS_MOBILE && <SimpMobile/>}
          {!IS_MOBILE && (
            <>
              <SimpDownloadBox/>
              <SimpSuccessDashboard/>
            </>
          )}
        </div>
      </section>
    </SimpWrapper>
  );
}

SimpSuccessPage.displayName = 'SimpSuccessPage';

export { SimpSuccessPage };
