import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import S from './qkn-input-field.module.scss';


const QknInputField = ({className, error, label, ...props}) => {
  return (
    <div className={className}>
      <label className={S.qknLabel} htmlFor={props.id}>{label}</label>
      <input
        {...props}
        type="text"
        className={classNames(S.qknInputField, {[S.error]: !!error})}
      />
      <p className={S.qknError}>{error}</p>
    </div>
  );
};

QknInputField.displayName = 'QknInputField';

QknInputField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export { QknInputField };
