import React from 'react';
import { IMAGES } from '../constants/constants';

export const useSetHead = (product = '') => {
  React.useEffect(() => {
    const IS_SIMP = product.toLowerCase() === 'simplifi';

    let link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = IS_SIMP ? IMAGES.FAVICON_SIMP : IMAGES.FAVICON_QKN;
    document.getElementsByTagName('head')[0].appendChild(link);

    document.title = `${product} Activation`;
  }, [product]);
};
