import React from 'react';

import { QknAutoRenew } from './qkn-auto-renew';
import { pageReducer } from '../../../helpers/context/page-context';
import { PageProvider } from '../../../helpers/providers/page-provider';

const PAGE_INIT_STATE = {
  isDisabled: true,
  isLoading: true,
  method: null,
  errors: {
    number: '',
    cvv: '',
    expirationDate: '',
    cardholderName: '',
    postalCode: ''
  }
};

const QknArFlow = (props) => {
  return (
    <PageProvider
      pageReducer={pageReducer}
      initState={PAGE_INIT_STATE}
    >
      <QknAutoRenew {...props} />
    </PageProvider>
  );
};

export { QknArFlow };
