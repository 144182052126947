export const getFlowData = (edu = '') => {
  edu = edu ? edu : '';

  switch (edu.toUpperCase() ?? '') {
    case 'UT': return {type: 'EDU', name: 'UT', plan: 'P6M', src: 'https://www.quicken.com/sites/default/files/simplifi-tx.png'};
    // 170325 6-month trial for UT -> P1Y $39.99

    case 'BUF': return {type: 'EDU', name: 'Buffalo State College', plan: 'P1M', src: 'https://www.quicken.com/sites/default/files/simplifi-buffalostate.svg'};
    // 170400 1-month trial for BUF -> P1M $3.99 170401
    // 170402 1-month trial for BUF -> P1Y $29.99 170403

    case 'UMI': return {type: 'EDU', name: 'University of Michigan', plan: 'P1M', src: null};
    // 170365 1-month trail for Michigan -> P1M  $3.99  170367
    // 170366 1-month trail for Michigan -> P1Y $29.99 170368

    case 'OLM': return {type: 'EDU', name: 'Ole Miss', plan: 'P1M', src: 'https://www.quicken.com/sites/default/files/simplifi-olemiss.svg'};
    // 170379 1-month trial for Ole Miss -> P1M  $3.99
    // 170381 1-month trial for Ole Miss -> P1Y $29.99

    case 'JMU': return {type: 'EDU', name: 'JMU', plan: 'P3M', src: 'https://www.quicken.com/sites/default/files/jmuaa_logo_purple_linear_web.png'};
    // 170383 3-month trial for JMU  -> P1M $3.99 170384
    // 170385 3-month trial for JMU -> P1Y $35.99 170386

    case 'ORG': return {type: 'EDU', name: 'University of Oregon', plan: 'P1M', src: 'https://www.quicken.com/sites/default/files/simplifi-uoforegon.svg'};
    // 170387 1-month    trial for Oregon -> P1M $3.99 170388
    // 170389 1-month(a) trial for Oregon -> P1Y $29.99 170390

    case '25YR': return {name: 'Quarter Centurions', type: '25YR', plan: 'P1Y', src: null,};
    // 1-year trial for 25Yr -> P1Y $35.99

    default: return {name: '', type: 'UNKNOWN', plan: null, src: undefined};
  }
};
