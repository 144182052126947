import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'react-slidedown/lib/slidedown.css';
import './index.module.scss';

import { parseCookies } from './helpers/page-helpers';
import { GlobalProvider } from './helpers/providers/global-provider';
import { Routes } from './components/routes';

const GLOBAL_INIT_STATE = {
  userId: '',
  email: '',
  code: '',
  hasAR: false,
  address: {},
  profile: {},
  meta: {hasData: false},
  name: {first: '', last: ''},
  simpSuccessObj: {lastDay: '', description: ''},
  payment: {method: null, last4: '', email: ''},
  flow: localStorage.getItem('flow'),
  product: {
    action: 'UNKNOWN',
    name: '',
    expiry: ''
  },
};

const COOKIE = parseCookies(document.cookie);
const params = new URLSearchParams(document.location.search);
const BEARER = params.get('bearer') || null;
console.log(`${process.env.REACT_APP_ENV} ${process.env.REACT_APP_BUILD}`);

if (COOKIE?.qkn_bearer) {
  localStorage.setItem('bearer', COOKIE?.qkn_bearer);
}
else if (BEARER) {
  localStorage.setItem('bearer', BEARER);
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider initState={GLOBAL_INIT_STATE}>
        <Routes/>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
