import React from 'react';

export const SuccessIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Group</title>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3-Add-payment-" transform="translate(-440.000000, -124.000000)" stroke="#2DD6A8" strokeWidth="2">
        <g id="account-created" transform="translate(441.000000, 124.000000)">
          <g id="Group" transform="translate(0.000000, 1.000000)">
            <circle id="Oval" cx="9" cy="9" r="9"/>
            <polyline id="Path-2" points="4.73684211 8.69986901 8.06381154 12.3157895 13.2631579 6.63157895" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
