import React from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router';

import S from './qkn-activation-form.module.scss';
import QknActivationHelp from './qkn-activation-help';
import { QknActivateTerms } from './qkn-activation-terms';
import { ActivationError } from '../../common/activation-error';
import * as GlobalCtx from '../../../helpers/context/global-context';
import * as PageCtx from '../../../helpers/context/page-context';
import { ROUTES } from '../../../helpers/constants/constants';
import { checkActivationCode } from '../../../network/network-calls';

const QknActivateForm = () => {
  const {push} = useHistory();
  const {globalDispatch, globalState: {userId, hasPayment}} = GlobalCtx.useGlobalCtx();
  const {pageDispatch, pageState: {code, isValid, isDisabled, hasError, errObj}} = PageCtx.usePageCtx();

  const handleCodeChange = React.useCallback(({target: {value}}) => {
    const FORMATTED_VALUE = value.substr(0, 19).toUpperCase();
    const RAW_VALUE = value.replace(/[^0-9A-Z]/g, '');

    pageDispatch(PageCtx.setPageFields({
      code: FORMATTED_VALUE,
      hasError: false,
      isValid: RAW_VALUE.length === 16
    }));
  }, [pageDispatch]);

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    try {
      pageDispatch(PageCtx.setPageFields({isDisabled: true}));

      await checkActivationCode(code, userId);
      globalDispatch(GlobalCtx.setGlobalFields({code}));
      push(hasPayment ? ROUTES.QKN_PROFILE : ROUTES.QKN_AUTO_RENEW);
    }
    catch (err) {
      console.log(err);
      pageDispatch(PageCtx.setPageFields({
        errObj: err?.response?.data?.errors?.[0],
        hasError: true,
        isDisabled: false
      }));
    }
  }, [globalDispatch, pageDispatch, push, userId, hasPayment, code]);

  return (
    <form className={S.qknActivationBox} onSubmit={handleSubmit}>
      <label htmlFor="activateCode">Enter your product activation code</label>

      <InputMask
        name="code"
        id="activateCode"
        mask="****-****-****-****"
        placeholder="XXXX-XXXX-XXXX-XXXX"
        maskChar=" "
        value={code}
        disabled={isDisabled}
        onChange={handleCodeChange}
        className={classNames(S.codeInput, {
          [S.error]: hasError,
          [S.success]: !hasError && isValid
        })}
      />

      {errObj && <ActivationError {...errObj} />}

      <QknActivationHelp/>

      <div className={S.descriptionArea}>
        <QknActivateTerms/>

        <button
          type="submit"
          className={S.qknBtn}
          disabled={!isValid}
        >ACTIVATE MEMBERSHIP
        </button>
      </div>

    </form>
  );
};

QknActivateForm.displayName = 'QknActivateForm';

export { QknActivateForm };
