import React from 'react';

import S from './qkn-activate.module.scss';
import { QknActivationFormCtx } from './qkn-activate-ctx';
import { QknWrapper } from '../qkn-wrapper';
import { LINKS } from '../../../helpers/constants/constants';

const QknActivate = () => {

  return (
    <QknWrapper>
      <section className={S.qknActivationArea}>
        <h1 className={S.qknH1}>Activate Quicken</h1>

        <QknActivationFormCtx />

        <article className={S.qknBuyBox}>
          <h2 className={S.qknH3}>Don&apos;t have an activation code?</h2>
          <a href={LINKS.QKN_COMPARE} className={S.qknLinkBtn}>BUY QUICKEN NOW</a>
        </article>

      </section>
    </QknWrapper>
  );
};

QknActivate.displayName = 'QknActivate';

export { QknActivate };
