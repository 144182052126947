import React from 'react';

import S from './simp-success-mobile.module.scss';
import { getStoreLink } from '../../../helpers/page-helpers';

function SimpMobile() {
  const LINK = getStoreLink();

  return (
    <article className={S.mobileBox}>
      <h3>Let&apos;s get started!</h3>
      <p>Download the Simplifi app and link your bank account to see your finances in one place.</p>

      <a href={LINK} className={S.blueBtn}>Download Simplifi</a>
    </article>
  );
}

SimpMobile.displayName = 'SimpMobile';

export { SimpMobile };
