import React from 'react';

import { SimpSuccessPage } from './simp-success.js';
import { pageReducer } from '../../../helpers/context/page-context';
import { PageProvider } from '../../../helpers/providers/page-provider';

const SUCCESS_PAGE_INIT_STATE = {
  mobile: '',
  isDisabled: false,
  isValid: false,
  isSuccess: false,
  hasError: false,
};

const SimpSuccessFlow = (props) => {
  return (
    <PageProvider
      pageReducer={pageReducer}
      initState={SUCCESS_PAGE_INIT_STATE}
    >
      <SimpSuccessPage {...props} />
    </PageProvider>
  );
};

SimpSuccessFlow.displayName = 'SimpSuccessFlow';

export { SimpSuccessFlow };
