import PropTypes from 'prop-types';
import React from 'react';

import { globalReducer } from '../context/global-context';
import { GLOBAL_CTX } from '../constants/constants';

const GlobalProvider = (props) => {
  const [state, dispatch] = React.useReducer(globalReducer, props.initState);

  const value = React.useMemo(() => [state, dispatch], [state]);

  return (
    <GLOBAL_CTX.Provider value={value} {...props}>
      {props.children}
    </GLOBAL_CTX.Provider>
  );
};

GlobalProvider.displayName = 'GlobalProvider';
GlobalProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initState: PropTypes.object.isRequired
};

export { GlobalProvider };
