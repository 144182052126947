import React from 'react';
import { SimpBanner } from './common/simp-banner';

import S from './simp-unavailable.module.scss';
import { SimpWrapper } from './simp-wrapper';
import { LINKS } from '../../helpers/constants/constants';

const SimpUnavailable = () => {
  return (
    <SimpWrapper>
      <SimpBanner type="FAIL" text="Ineligible"/>
      <section className={S.subscribed}>
        <h1 className={S.h1Heading}>Oops, this activation code is invalid</h1>
        <h2>This activation code may have expired or has been previously used</h2>

        <p className={S.simpHelp}>
          Questions?
          <a href={LINKS.SIMP_SUPPORT} className={S.linkBtn}>Contact support</a>
        </p>
      </section>
    </SimpWrapper>
  );
};

SimpUnavailable.displayName = 'SimpUnavailable';

export { SimpUnavailable };
