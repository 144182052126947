import React from 'react';
import { parse } from 'query-string';
import { Link } from 'react-router-dom';

import S from './simp-landing.module.scss';
import { SimpWrapper } from './simp-wrapper';
import * as GlobalCtx from '../../helpers/context/global-context';
import { LINKS, ROUTES } from '../../helpers/constants/constants';
import { getFlowData } from '../../helpers/flow-helpers';
import { getSimpOffer } from '../../helpers/page-helpers';

const SimpLandingPage = () => {
  const {globalDispatch, globalState: {flow}} = GlobalCtx.useGlobalCtx();

  React.useEffect(() => {
    const { flow, activation } = parse(window.location.search);
    localStorage.setItem('flow', flow || 'UNKNOWN');
    localStorage.setItem('product', 'simplifi');
    if (activation) {
      localStorage.setItem('activation_code', activation);
    }
    globalDispatch(GlobalCtx.setGlobalFields({flow}));
  }, [globalDispatch]);

  const {plan, type, name} = getFlowData(flow);
  const IS_EDU = type === 'EDU';

  const OFFER = getSimpOffer(plan);

  return (
    <SimpWrapper className={S.topPadding}>
      <section className={S.landingArea}>

        {!IS_EDU && <h1 className={S.genericHeading}>Gain insights to stay on top of your money.</h1>}
        {IS_EDU && (
          <>
            <h1 className={S.eduH1}>First smart decision: graduate from {name}.</h1>
            <h2 className={S.eduH2}>Your next smart decision: saving with Simplifi.</h2>
          </>
        )}

        {!IS_EDU && (
          <p className={S.description}>
            Elegantly modern. Powerfully simple.
            Simplifi by Quicken is the personal finance app that works hard so you don&apos;t have to.
            Activate your {OFFER ? <span>{OFFER} subscription</span> : 'subscription'}.
          </p>
        )}
        {IS_EDU && (
          <p className={S.description}>
            Simplifi helps you save smart, spend well, and live right.
            Take advantage of your {name} alumni perks and create an account to activate
            your {OFFER ? <span>{OFFER} subscription</span> : 'subscription'}.
          </p>
        )}

        <Link to={ROUTES.SIMP_LOGIN} className={S.logInBtn}>Get Started</Link>
        <a className={S.aboutLink} href={LINKS.SIMPLIFI}>What is Simplifi?</a>
      </section>
    </SimpWrapper>
  );
};

SimpLandingPage.displayName = 'SimpLandingPage';

export { SimpLandingPage };
