import React from 'react';

import { SimpArPage } from './simp-ar';
import { ZIP_REGEX } from '../../../helpers/constants/regex';
import { useGlobalCtx } from '../../../helpers/context/global-context';
import { pageReducer } from '../../../helpers/context/page-context';
import { PageProvider } from '../../../helpers/providers/page-provider';

export const PAYPAL_VALIDATIONS = {
  name: text => text ? '' : 'Please make a selection',
  zip: text => ZIP_REGEX.test(text) ? '' : 'Please check zip code',
};

const PAGE_INIT_STATE = state => ({
  isDisabled: true,
  isLoading: true,
  showHelp: false,
  nonce: '',
  email: '',
  form: {
    name: `${state.name.first}${state.name.last ? ` ${state.name.last}` : ''}`,
    line1: '',
    line2: '',
    city: '',
    zip: state.address?.zipCode || '',
    state: '',
    country: 'us',
  },
  errors: {
    nonce: '',
    number: '',
    cvv: '',
    expirationDate: '',
    cardholderName: '',
    postalCode: '',
    name: '',
    zip: '',
  },
});

const SimpArFlow = (props) => {
  const {globalState} = useGlobalCtx();

  return (
    <PageProvider
      pageReducer={pageReducer}
      initState={PAGE_INIT_STATE(globalState)}
    >
      <SimpArPage {...props} />
    </PageProvider>
  );
};

SimpArFlow.displayName = 'SimpArFlow';

export { SimpArFlow };
