import { parse } from 'query-string';
import PropTypes from 'prop-types';
import React from 'react';

import * as NetworkCalls from '../network/network-calls';
import { ROUTES } from '../helpers/constants/constants';

const LoginCallback = ({location: {search = ''}}) => {
  const authenticate = React.useCallback(async () => {
    try {
      const {code} = parse(search);
      const PRODUCT = localStorage.getItem('product') || '';
      const IS_SIMP = PRODUCT.toLowerCase() === 'simplifi';

      const {data} = await NetworkCalls.getOAuthToken(code, IS_SIMP);

      localStorage.setItem('bearer', data.accessToken);

      window.top.location.href = ROUTES.GET_DATA;
    }
    catch (err) {
      console.log(err);
    }
  }, [search]);

  React.useEffect(() => {
    authenticate();
  }, [authenticate]);

  return <div />;
};

LoginCallback.displayName = 'LoginCallback';
LoginCallback.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export { LoginCallback };
