import hostedFields from 'braintree-web/hosted-fields';
import paypalCheckout from 'braintree-web/paypal-checkout';

import * as pageCtx from './context/page-context';

export const initBrainTree = (client, idObj, cb) => {
  hostedFields.create({
    client,
    fields: {
      cardholderName: {selector: idObj.name},
      cvv: {selector: idObj.cvv},
      postalCode: {selector: idObj.postal},

      number: {
        selector: idObj.number,
        placeholder: 'XXXX XXXX XXXX XXXX'
      },

      expirationDate: {
        selector: idObj.date,
        placeholder: 'MM / YY'
      }
    }
  }, cb);
};

export const initPayPal = (client, onSuccess) =>
  paypalCheckout.create(
    {client},
    (clientErr, paypalCheckoutInstance) => {
      if (clientErr) {
        console.error('Error paypalCheckout:', clientErr);
        return;
      }

      paypalCheckoutInstance.loadPayPalSDK({
        vault: true,
        intent: 'tokenize'
      }, ((tokenErr, paypalCheckoutInstance) => {
        if (tokenErr) {
          console.error('Error loadPayPalSDK:', tokenErr);
          return;
        }

        return paypal.Buttons({
          fundingSource: 'paypal',
          style: {
            layout: 'horizontal',
            label: 'checkout',
          },

          createBillingAgreement: () => paypalCheckoutInstance.createPayment({flow: 'vault'}),
          // onCancel: data => console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2)),
          onError: (err) => console.error('PayPal error', err),

          onApprove: data =>
            paypalCheckoutInstance.tokenizePayment(data)
              .then(payload => onSuccess(payload))
              .catch(err => console.log(err))
        }).render('#paypal-button');
      }));
    });


export function getErrorMsgs(field) {
  switch (field) {
    case 'number':
      return 'Please enter a valid card number';
    case 'expirationDate':
      return 'Please enter a valid expiration date';
    case 'cvv':
      return 'Please enter valid security code';
    case 'cardholderName':
      return 'Please enter your name as shown on the credit card';
    case 'postalCode':
      return 'Please enter a valid zip code';
    case 'name':
      return 'Please enter your name';
    case 'zip':
      return 'Please enter a valid zip code';

    default:
      return '';
  }
}

export function initHostedFields(dispatch, setHostedFields, err, hostedFields) {
  if (err) {
    return console.log(err);
  }

  setHostedFields(hostedFields);
  hostedFields.on('blur', event => onBTBlur(event, dispatch));
  hostedFields.on('validityChange', event => onBTValidityChange(event, dispatch));
  dispatch(pageCtx.setPageFields({
    isDisabled: false,
    isLoading: false
  }));
}


export function onBTBlur({emittedBy, fields}, dispatch) {
  fields[emittedBy].container.class = 'braintree-hosted-fields-invalid';

  const {isEmpty, isValid, isPotentiallyValid} = fields[emittedBy];

  const MSG = (!isEmpty && (isValid || isPotentiallyValid))
    ? ''
    : getErrorMsgs(emittedBy);
  dispatch(pageCtx.setPageErrorFields(emittedBy, MSG));
}


export function onBTValidityChange({emittedBy, fields}, dispatch) {
  const {isValid, isPotentiallyValid} = fields[emittedBy];

  const MSG = (isValid || isPotentiallyValid) ? '' : getErrorMsgs(emittedBy);
  dispatch(pageCtx.setPageErrorFields(emittedBy, MSG));
}


export const handlePayPalSubmit = (dispatch, onSuccess, onFail) => {
  dispatch(pageCtx.setPageFields({isDisabled: true}));

  hostedFields.tokenize()
    .then(data => onSuccess(data))
    .catch(err => onFail(err));
};

export const handleSubmit = (dispatch, hostedFields, onSuccess, onFail) => {
  const ERROR_FIELDS = Object.keys(hostedFields._state.fields)
    .reduce((acm, cur) => {
      const {isEmpty, isValid, isPotentiallyValid} = hostedFields._state.fields[cur];
      return (!isEmpty && (isValid || isPotentiallyValid)) ? acm : acm.concat(cur);
    }, []);

  if (ERROR_FIELDS.length > 0) {
    ERROR_FIELDS.forEach(field =>
      dispatch(pageCtx.setPageErrorFields(field, getErrorMsgs(field))));
  }
  else {
    dispatch(pageCtx.setPageFields({isDisabled: true}));

    hostedFields.tokenize()
      .then(data => onSuccess(data))
      .catch(err => onFail(err));
  }
};
