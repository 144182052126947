import React from 'react';

import S from './simp-terms.module.scss';
import { LINKS } from '../../../helpers/constants/constants';

function SimpTerms() {
  return (
    <p className={S.simpTerms}>
      By clicking
      <span>activate membership,</span>
      I agree to the Quicken Inc.
      <a className={S.linkBtn} href={LINKS.SIMP_TERMS}>Terms of Use</a>
      and
      <a className={S.linkBtn} href={LINKS.PRIVACY}>Privacy Policy</a>
    </p>
  );
}

SimpTerms.displayName = 'SimpTerms';

export { SimpTerms };
