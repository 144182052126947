import PropTypes from 'prop-types';
import React from 'react';

const QknSuccessBanner = ({action, name, date}) => {
  switch(action) {
    case 'CREATE':
      return <p>You&apos;ve activated <span>{name}</span> - Plan ends on {date}</p>;

    case 'DOWNGRADE':
      return <p>You&apos;ve downgraded to <span>{name}</span> - Plan ends on {date}</p>;

    case 'EXTEND':
      return <p>You&apos;ve extended your subscription to <span>{name}</span> - Plan ends on {date}</p>;

    case 'UPGRADE':
      return <p>You&apos;ve upgraded your subscription to <span>{name}</span> - Plan ends on {date}</p>;

    default: return <p>Your subscription has been activated</p>;
  }
};

QknSuccessBanner.displayName = 'QknSuccessBanner';
QknSuccessBanner.propTypes = {
  action: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export { QknSuccessBanner };
