import React from 'react';

export const DownLoadIcon = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icon</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="350" height="394" rx="15"/>
      <filter x="-4.3%" y="-3.8%" width="108.6%" height="107.6%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="3" operator="erode" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0.615686275   0 0 0 0 0.611764706   0 0 0 0 0.682352941  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"/>
      </filter>
    </defs>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="5-Confirmation-" transform="translate(-710.000000, -757.000000)">
        <g id="dots" transform="translate(0.000000, 684.000000)" fill="#F5F5FF" fillRule="nonzero">
          <ellipse id="Oval" cx="731.524298" cy="74.5" rx="2.52224504" ry="2.39285714"/>
          <ellipse id="Oval" cx="717.181383" cy="74.5" rx="2.52224504" ry="2.39285714"/>
          <ellipse id="Oval" cx="731.524298" cy="88.5357143" rx="2.52224504" ry="2.39285714"/>
          <ellipse id="Oval" cx="717.181383" cy="88.5357143" rx="2.52224504" ry="2.39285714"/>
          <ellipse id="Oval" cx="731.524298" cy="102.571429" rx="2.52224504" ry="2.39285714"/>
          <ellipse id="Oval" cx="717.181383" cy="102.571429" rx="2.52224504" ry="2.39285714"/>
        </g>
        <g id="right-box" transform="translate(550.000000, 555.000000)">
          <g id="Rectangle-Copy">
            <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"/>
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"/>
          </g>
          <g id="icon" transform="translate(160.000000, 204.000000)">
            <rect id="Rectangle" fill="#6FEAB9" x="0" y="6" width="24" height="24"/>
            <g id="Group-4" transform="translate(11.000000, 0.000000)" stroke="#35364D" strokeWidth="3">
              <g id="Group-3" transform="translate(9.500000, 9.500000) rotate(-45.000000) translate(-9.500000, -9.500000) translate(-3.000000, 9.000000)">
                <line x1="0.491768973" y1="0.5" x2="25" y2="0.5" id="Path-3"/>
              </g>
              <polyline id="Path-4" points="5 0 18.7186675 0 18.7186675 14.1959872"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
