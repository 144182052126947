import React from 'react';
import { useHistory } from 'react-router';
import { QknWrapper } from '../qkn-wrapper';

import S from './qkn-profile.module.scss';
import { QknInputField } from '../inputs/qkn-input-field';
import { QknSelectField } from '../inputs/qkn-select-field';
import * as Calls from '../../../network/network-calls';
import * as Options from '../../../helpers/constants/options-constants';
import * as GlobalCtx from '../../../helpers/context/global-context';
import * as PageCtx from '../../../helpers/context/page-context';
import { ROUTES } from '../../../helpers/constants/constants';

const VALIDATIONS = {
  retailer: text => text ? '' : 'Please make a selection',
  experience: text => text ? '' : 'Please make a selection',
  firstName: text => text ? '' : 'Please enter your first name',
  lastName: text => text ? '' : 'Please enter your last name',
  line1: text => text ? '' : 'Please enter your address',
  line2: () => '',
  city: text => text ? '' : 'Please enter your city',
  state: text => text ? '' : 'Please enter your state',
  zipCode: text => text ? '' : 'Please enter your zip code'
};

const QknProfile = () => {
  const { push } = useHistory();
  const {globalDispatch, globalState: {userId, code, hasAR, profileId, address}} = GlobalCtx.useGlobalCtx();
  const {pageDispatch, pageState: {form, errors, isDisabled}} = PageCtx.usePageCtx();

  const {xpLabel, xpOptions} = React.useMemo(
    () => address.line1 ? {
      xpLabel: 'Last version of Quicken you\'ve used?',
      xpOptions: Options.NEW_XP_OPTIONS
    } : {
      xpLabel: 'Are you new to Quicken?',
      xpOptions: Options.OLD_XP_OPTIONS
    },
    [address.line1]
  );

  const handleInputField = React.useCallback((field, payload) => {
    pageDispatch(PageCtx.setPageInputField(field, payload));
  }, [pageDispatch]);

  const handleSelection = React.useCallback(({target: {value, name}}) => {
    handleInputField(name, {
      form: value,
      error: VALIDATIONS[name](value)
    });
  }, [handleInputField]);


  const handleChange = React.useCallback(({type, target: {value, name}}) => {
    if (type === 'blur') {
      value.trim();
    }

    handleInputField(name, {
      form: value,
      error: VALIDATIONS[name](value)
    });
  }, [handleInputField]);


  const handleSubmit = async e => {
    e.preventDefault();
    const ERROR_OBJ = Object.keys(form).reduce((acm, cur) => ({
      ...acm, [cur]: VALIDATIONS[cur](form[cur])
    }), {});

    if (!Object.values(ERROR_OBJ).every(v => v === '')) {
      return pageDispatch(PageCtx.setPageField('errors', ERROR_OBJ));
    }

    const [Activation] = await Promise.all([
      Calls.callActivateCode({
        userId: userId,
        code: code,
        autoRenew: hasAR,
        retailer: form.retailer,
        experience: form.experience
      }),
      Calls.callUpdateUserNames({
        userId: profileId,
        firstName: form.firstName,
        lastName: form.lastName
      }),
      Calls.callUpdateAddress({
        fullName: `${form.firstName} ${form.lastName}`,
        line1: form.line1,
        line2: form.line2,
        city: form.city,
        state: form.state,
        country: 'us',
        zipCode: form.zipCode
      })
    ]);

    const {id, action, postState: {tierName, expireOn}} = Activation.data.subscriptionResponse;

    const {data} = await Calls.callUpdateSurvey({
      subId: id,
      retailer: form.retailer,
      experience: form.experience,
      channel: 'DIRECT' //'INPRODUCT' TODO figure out how to tell the channel
    });

    console.log(data);

    globalDispatch(GlobalCtx.setGlobalFields({
      name: {
        first: form.firstName,
        last: form.lastName
      },
      product: {
        action,
        name: `Quicken ${tierName}`,
        date: new Date(expireOn).toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })
      }
    }));

    push(ROUTES.QKN_SUCCESS);
  };


  return (
    <QknWrapper>
      <section className={S.profileArea}>
        <h1 className={S.qknH1}>Quicken Member Profile</h1>
        <form className={S.profileBox} onSubmit={handleSubmit}>
          <h2>Please complete all fields</h2>

          <div className={S.purchaseInfo}>
            {[
              {
                id: 'profileRetailer',
                name: 'retailer',
                label: 'Where did you buy Quicken?',
                options: Options.RETAILER_OPTIONS
              },
              {id: 'profileExperience', name: 'experience', label: xpLabel, options: xpOptions}
            ].map(props => (
              <QknSelectField
                {...props}
                key={props.id}
                value={form[props.name]}
                error={errors[props.name]}
                disabled={isDisabled}
                onChange={handleSelection}
              >
                {props.options.map(({value, label}) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </QknSelectField>
            ))}
          </div>

          <div className={S.profileInfo}>
            {[
              {label: 'First Name', id: 'profileFirstName', name: 'firstName', className: S.fNameField},
              {label: 'Last Name', id: 'profileLastName', name: 'lastName', className: S.lNameField},
              {label: 'Address', id: 'profileLine1', name: 'line1', className: S.line1Field, placeholder: 'Enter a location'},
              {label: 'Address Optional', id: 'profileLine2', name: 'line2', className: S.line2Field, placeholder: 'Apt, Suite, etc. (Optional)'},
              {label: 'City', id: 'profileCity', name: 'city', className: S.cityField}
            ].map(({className, ...props}) => (
              <QknInputField
                {...props}
                key={props.id}
                className={className}
                value={form[props.name]}
                error={errors[props.name]}
                disabled={isDisabled}
                onChange={handleChange}
                onBlur={handleChange}
              />
            ))}

            <QknSelectField
              label="State / Province"
              id="profileState"
              name="state"
              value={form.state}
              disabled={isDisabled}
              onChange={handleSelection}
              error={errors.state}
              className={S.stateField}
            >
              <option value="">Make a selection</option>
              <optgroup label="US States">
                {Options.STATE_OPTIONS.map(({value, label}) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </optgroup>
              <optgroup label="Armed Forces">
                {Options.MILITARY_OPTIONS.map(({value, label}) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </optgroup>
              <optgroup label="Canadian Provinces">
                {Options.CA_PROVINCE.map(({value, label}) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </optgroup>
            </QknSelectField>

            <QknInputField
              label="Zip Code"
              id="profilePostal"
              name="zipCode"
              className={S.postalField}
              value={form.zipCode}
              error={errors.zipCode}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
            />
          </div>

          <button
            type="submit"
            className={S.submitBtn}
            disabled={isDisabled}
          >CONTINUE</button>
        </form>
      </section>
    </QknWrapper>
  );
};

QknProfile.displayName = 'QknProfile';

export { QknProfile };
