import React from 'react';
import PropTypes from 'prop-types';

import S from './simp-logo-area.module.scss';
import { IMAGES } from '../../../helpers/constants/constants';


function SimpLogoArea({src, name}) {
  return (
    <div className={S.logoArea}>
      <img src={IMAGES.SIMP_LOGO} alt="Simplifi Logo" />
      {src && <img className={S.otherLogo} src={src} alt={`${name} logo`}/>}
    </div>
  );
}

SimpLogoArea.displayName = 'SimpLogoArea';
SimpLogoArea.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
};

export {SimpLogoArea};
