import Axios from 'axios';

import { ROUTES } from '../helpers/constants/constants';

export const initAxios = (product) => {
  product = product.toLowerCase();

  Axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        const ERROR_LIST = error?.response?.data?.errors || [];
        if (ERROR_LIST.some(({code}) => code === 'QCS-0401-2')) {
          console.log('LOGIN_EXPIRED');

          localStorage.removeItem('bearer');

          window.top.location.href = product === 'simplifi'
            ? ROUTES.SIMP_LOGIN
            : ROUTES.QKN_LOGIN;
        }
      }

      return Promise.reject(error);
    });
};
