import React from 'react';
import PropTypes from 'prop-types';

import S from './simp-payment-label.module.scss';

const PaymentLabel = ({isChecked, value, label, src, alt, id, onChange}) => {
  return (
    <label className={S.paymentLabel} htmlFor={`payment_method_${id}`}>
      <div>
        <input
          type="radio"
          name="method"
          value={value}
          id={`payment_method_${id}`}
          checked={isChecked}
          onChange={onChange}
        />

        {label}
      </div>
      <img src={src} alt={alt} />
    </label>
  );
};

PaymentLabel.displayName = 'PaymentLabel';
PaymentLabel.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

export { PaymentLabel };
