import React from 'react';
import classNames from 'classnames';
import client from 'braintree-web/client';
import { useHistory } from 'react-router';

import S from './qkn-ar-form.module.scss';
import QknARTerms from './qkn-ar-terms';
import QknCvvHelp from './qkn-cvv-help';
import { BrainTreeField } from '../../common/brain-tree-fields';
import { callUpdatePaymentNonce } from '../../../network/network-calls';
import { IMAGES, ROUTES } from '../../../helpers/constants/constants';
import * as GlobalCtx from '../../../helpers/context/global-context';
import * as PageCtx from '../../../helpers/context/page-context';
import * as brainTreeHelpers from '../../../helpers/braintree-helpers';


const TOP_AR_FIELDS = [
  {className: S.ccFieldNum, label: 'Card Number', id: 'qkn-cc-number', name: 'number'},
  {className: S.ccFieldDate, label: 'Expiration', id: 'qkn-cc-expires', name: 'expirationDate'}
];

const BOTTOM_AR_FIELDS = [
  {className: S.ccFieldName, label: 'Name on Card', id: 'qkn-cc-name', name: 'cardholderName'},
  {className: S.ccFieldPostal, label: 'Postal Code', id: 'qkn-cc-postal', name: 'postalCode'}
];


const QknAutoRenewForm = () => {
  const {globalDispatch} = GlobalCtx.useGlobalCtx();
  const {pageDispatch, pageState: {showHelp, isDisabled, errors}} = PageCtx.usePageCtx();
  const { push } = useHistory();
  const [_hostedFields, setHostedFields] = React.useState(null);

  const handleHelpToggle = React.useCallback(
    () => pageDispatch(PageCtx.setPageField('showHelp', !showHelp)),
    [pageDispatch, showHelp]
  );


  const onSubmitSuccess = React.useCallback(async ({nonce}) => {
    await callUpdatePaymentNonce(nonce);
    globalDispatch(GlobalCtx.setGlobalFields({hasAR: true}));
    push(ROUTES.QKN_PROFILE);
  }, [globalDispatch, push]);


  const onSubmitFailure = React.useCallback(err => {
    console.log(err);
    pageDispatch(PageCtx.setPageFields({
      isDisabled: false,
      isLoading: false
    }));
  }, [pageDispatch]);


  const handleSubmit = React.useCallback(e => {
    e.preventDefault();
    brainTreeHelpers.handleSubmit(
      pageDispatch,
      _hostedFields,
      onSubmitSuccess,
      onSubmitFailure
    );
  }, [pageDispatch, _hostedFields, onSubmitSuccess, onSubmitFailure]);


  const handleSkipAr = React.useCallback(() => {
    push(ROUTES.QKN_PROFILE);
  }, [push]);

  const createHostedField = React.useCallback((clientErr, client) => {
    if (clientErr) {
      console.error('Error creating client:', clientErr);
      return;
    }

    brainTreeHelpers.initBrainTree(client, {
      name: '#qkn-cc-name',
      number: '#qkn-cc-number',
      cvv: '#qkn-cvv',
      date: '#qkn-cc-expires',
      postal: '#qkn-cc-postal'
    }, brainTreeHelpers.initHostedFields.bind(null, pageDispatch, setHostedFields));
    brainTreeHelpers.initPayPal(client, onSubmitSuccess, {layout: 'horizontal'});
  }, [pageDispatch, onSubmitSuccess]);

  React.useEffect(() => {
    client.create(
      {authorization: process.env.REACT_APP_BRAINTREE_TOKEN},
      createHostedField
    );
  }, [createHostedField]);

  return (
    <form className={S.autoRenewForm} onSubmit={handleSubmit}>

      <div className={S.paypal}>
        <h2 className={S.heading}>PayPal</h2>
        <div id="paypal-button"/>
      </div>

      <h2 className={S.heading}>Debit / Credit Card</h2>

      <div className={S.creditHeader}>
        <img src={IMAGES.CC_CARDS} alt="credit card logos"/>
      </div>

      <div className={S.inputArea}>

        {TOP_AR_FIELDS.map(props => (
          <BrainTreeField
            key={props.id}
            error={errors[props.name]}
            errorClass={S.qknError} {...props}
          />
        ))}

        <div className={S.ccFieldCvv}>
          <label htmlFor="qkn-cvv">CVV
            {showHelp && <QknCvvHelp onClose={handleHelpToggle}/>}
            <button
              type="button"
              className={S.cvvHelpBtn}
              onClick={handleHelpToggle}
            >What is this?</button>
          </label>

          <div id="qkn-cvv" className={classNames({hasError: errors.cvv})}/>
          <p className={S.qknError}>{errors.cvv}</p>
        </div>

        {BOTTOM_AR_FIELDS.map(props => (
          <BrainTreeField
            key={props.id}
            error={errors[props.name]}
            errorClass={S.qknError}
            {...props}
          />
        ))}
      </div>

      <QknARTerms/>

      <div className={S.actionRow}>
        <button
          type="button"
          className={S.qknLinkBtn}
          disabled={isDisabled}
          onClick={handleSkipAr}
        >SKIP OFFER</button>

        <button
          type="submit"
          className={S.submitARBtn}
          disabled={isDisabled}
        >ENABLE AUTO-RENEW</button>
      </div>
    </form>
  );
};

QknAutoRenewForm.displayName = 'QknAutoRenewForm';

export { QknAutoRenewForm };
