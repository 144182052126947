import React from 'react';

import S from './simp-ar.module.scss';
import * as PageCtx from '../../../helpers/context/page-context';
import { BrainTreeField } from '../../common/brain-tree-fields';


const AR_FIELDS = [
  {className: S.ccFieldNum, label: 'Card number', id: 'simp-cc-number', name: 'number'},
  {className: S.ccFieldDate, label: 'Expiration', id: 'simp-cc-expires', name: 'expirationDate'},
  {className: S.ccFieldCvv, label: 'Security code', id: 'simp-cvv', name: 'cvv'},
  {className: S.ccFieldName, label: 'Name on card', id: 'simp-cc-name', name: 'cardholderName'},
  {className: S.ccFieldPostal, label: 'Zip code', id: 'simp-cc-postal', name: 'postalCode'}
];


const SimpCCForm = () => {
  const {pageState: {errors}} = PageCtx.usePageCtx();

  return (
    <div className={S.inputArea}>
      {AR_FIELDS.map(props => (
        <BrainTreeField
          key={props.id}
          error={errors?.[props.name]}
          errorClass={S.simpError}
          {...props}
        />
      ))}
    </div>
  );
};

SimpCCForm.displayName = 'SimpCCForm';

export { SimpCCForm };
