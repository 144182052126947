import React from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from '../helpers/constants/constants';

const LogOutComponent = () => {
  const {push} = useHistory();

  React.useEffect(() => {
    const PRODUCT = localStorage.getItem('product') || '';
    localStorage.clear();

    push(PRODUCT.toLowerCase() === 'simplifi'
      ? ROUTES.SIMP_LANDING
      : ROUTES.QKN_LOGIN
    );
  }, [push]);

  return null;
};

export { LogOutComponent };
