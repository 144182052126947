import { useEffect } from 'react';

export const useOutsideClick = (ref, func) => {
  useEffect(() => {
    const outsideClick = ({target}) => {
      // eslint-disable-next-line
      if (!ref?.current?.contains(target)) {
        func(target);
      }
    };
    document.addEventListener('mouseup', outsideClick);
    return () => document.removeEventListener('mouseup', outsideClick);
  }, [ref, func]);
};
