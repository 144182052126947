import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import S from './simp-activation.module.scss';
import { SimpTerms } from './simp-terms';
import { SimpWrapper } from '../simp-wrapper';
import { ActivationError } from '../../common/activation-error';
import { checkActivationCode } from '../../../network/network-calls';
import { ROUTES } from '../../../helpers/constants/constants';
import * as GlobalCtx from '../../../helpers/context/global-context';
import * as PageCtx from '../../../helpers/context/page-context';


function SimpActivation({history: {push}}) {
  const {globalDispatch, globalState: {userId}} = GlobalCtx.useGlobalCtx();
  const {pageState: {isDisabled, hasError, isValid, errObj, code}, pageDispatch} = PageCtx.usePageCtx();

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    try {
      pageDispatch(PageCtx.setPageFields({isDisabled: true}));
      await checkActivationCode(code, userId);

      globalDispatch(GlobalCtx.setGlobalField('code', code));
      push(ROUTES.SIMP_AUTO_RENEW);
    }
    catch (err) {
      pageDispatch(PageCtx.setPageFields({
        errObj: err?.response?.data?.errors?.[0],
        hasError: true,
        isDisabled: false,
      }));
    }
  }, [pageDispatch, globalDispatch, push, code, userId]);

  const handleCodeChange = React.useCallback(({target: {value}}) => {
    const FORMATTED_VALUE = value.substr(0,19).toUpperCase();
    const RAW_VALUE = value.replace(/[^0-9A-Z]/g, '');

    pageDispatch(PageCtx.setPageFields({
      code: FORMATTED_VALUE,
      hasError: false,
      isValid: RAW_VALUE.length === 16,
    }));
  }, [pageDispatch]);

  return (
    <SimpWrapper>

      <form className={S.simpActivationPage} onSubmit={handleSubmit}>
        <h1 className={S.h1Heading}>Enter your Simplifi activation code</h1>

        <InputMask
          mask="****-****-****-****"
          placeholder="XXXX-XXXX-XXXX-XXXX"
          maskChar=" "
          value={code}
          onChange={handleCodeChange}
          disabled={isDisabled}
          className={classNames(S.codeInput, {
            [S.error]: hasError,
            [S.success]: !hasError && isValid
          })}
        />

        {errObj && (
          <div className={S.errorMsg}>
            <ActivationError additionalClass={S.errorMsg} {...errObj} />
          </div>
        )}


        <button
          type="submit"
          disabled={isDisabled}
          className={S.submitBtn}
        >Activate membership</button>

        <SimpTerms/>
      </form>
    </SimpWrapper>
  );
}

SimpActivation.displayName = 'FormAccount';
SimpActivation.propTypes = {
  history: PropTypes.object.isRequired
};
export { SimpActivation };
