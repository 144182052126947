import React from 'react';
import classNames from 'classnames';

import S from './simp-paypal-form.module.scss';
import { PAYPAL_VALIDATIONS } from './simp-ar-root';
import * as PageCtx from '../../../helpers/context/page-context';
import { INTERVAL_REGEX } from '../../../helpers/constants/regex';

const SimpPayPalForm = () => {
  const { pageDispatch, pageState: {errors, form, isDisabled}} = PageCtx.usePageCtx();

  const handleInputUpdate = React.useCallback(({type, target: {name, value}}) => {
    const IS_BLUR = type === 'blur';
    value = IS_BLUR ? value.trim() : value;
    value = name === 'zip' ? value.replace(INTERVAL_REGEX, '').slice(0, 5) : value;

    pageDispatch(PageCtx.setPageInputField(name, {
      form: value,
      error: IS_BLUR ? PAYPAL_VALIDATIONS[name](value) : ''
    }));
  }, [pageDispatch]);

  return (
    <div className={S.paypalRow}>

      <p className={S.description}>
        You will be taken to the PayPal sign in screen. Sign in to complete your payment securely.
      </p>

      <div className={S.paypalArea}>
        <div id="paypal-button"/>
        <p>{errors.nonce}</p>
      </div>

      <div className={S.nameField}>
        <label htmlFor="ppNameField">Name</label>
        <input
          type="text"
          name="name"
          id="ppNameField"
          value={form.name}
          disabled={isDisabled}
          onChange={handleInputUpdate}
          onBlur={handleInputUpdate}
          className={classNames(S.simpTextInput, {[S.error]: errors.name})}
        />
        <p>{errors.name}</p>
      </div>

      <div className={S.zipField}>
        <label htmlFor="ppZipField">Zip Code</label>
        <input
          type="text"
          name="zip"
          id="ppZipField"
          value={form.zip}
          disabled={isDisabled}
          onChange={handleInputUpdate}
          onBlur={handleInputUpdate}
          className={classNames(S.simpTextInput, {[S.error]: errors.name})}
        />
        <p>{errors.zip}</p>
      </div>

    </div>
  );
};

SimpPayPalForm.displayName = 'SimpPayPalForm';

export { SimpPayPalForm };
