import React from 'react';

import S from './simp-subscribed.module.scss';
import { SimpWrapper } from './simp-wrapper';
import { LINKS } from '../../helpers/constants/constants';
import { getStoreLink } from '../../helpers/page-helpers';


const SimpSubscribed = () => {
  const LINK = getStoreLink();

  return (
    <SimpWrapper>
      <section className={S.subscribed}>
        <h1 className={S.h1Heading}>Congrats, you’re already subscribed!</h1>

        <a href={LINK} className={S.myAcctBtn}>Go to Simplifi</a>

        <p className={S.simpHelp}>
          Questions?
          <a href={LINKS.SIMP_SUPPORT} className={S.linkBtn}>Contact support</a>
        </p>
      </section>
    </SimpWrapper>
  );
};

SimpSubscribed.displayName = 'SimpSubscribed';

export { SimpSubscribed };
