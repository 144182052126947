import classNames from 'classnames';
import React from 'react';
import InputMask from 'react-input-mask';

import S from './simp-success-download.module.scss';
import { sendSMS } from '../../../network/network-calls';
import * as PageCtx from '../../../helpers/context/page-context';
import { SMS_MSG } from '../../../helpers/constants/constants';

function SimpDownloadBox() {
  const {
    pageDispatch,
    pageState: {mobile, isDisabled, isValid, isSuccess, hasError}
  } = PageCtx.usePageCtx();

  const handleChange = React.useCallback(({target: {value}}) => {
    const FORMATTED_VALUE = value.substr(0,12).toUpperCase();
    const RAW_VALUE = value.replace(/[^0-9]/g, '');

    pageDispatch(PageCtx.setPageFields({
      mobile: FORMATTED_VALUE,
      isValid: RAW_VALUE.length === 10,
    }));

  }, [pageDispatch]);

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    try {
      pageDispatch(PageCtx.setPageFields({
        isDisabled: true,
        hasError: false,
      }));

      await sendSMS(mobile, SMS_MSG);
      pageDispatch(PageCtx.setPageFields({isSuccess: true}));
    }
    catch (err) {
      console.log(err);
      pageDispatch(PageCtx.setPageFields({
        hasError: true,
        isDisabled: false
      }));
    }
  }, [pageDispatch, mobile]);

  return (
    <article className={S.downloadWrapper}>

      <form className={S.downloadBox} onSubmit={handleSubmit}>
        <h3>Download the Simplifi app to get started …</h3>
        <p className={S.description}>Enter your mobile number and we’ll text a link to you.</p>

        <InputMask
          name="code"
          id="downloadMobile"
          mask="999-999-9999"
          placeholder="XXX-XXX-XXXX"
          maskChar=" "
          value={mobile}
          onChange={handleChange}
          disabled={isDisabled}
          className={classNames(S.codeInput, {
            [S.error]: false,
            [S.success]: isValid
          })}
        />

        <p className={S.msgFail}>{hasError ? 'Could not send text, please try again' : ''}</p>

        <button
          type="submit"
          className={classNames(S.submitBtn, {[S.isSuccess]: isSuccess})}
          disabled={isDisabled}
        >{isSuccess ? 'Link Sent' : 'Send me the link'}</button>

        <p className={S.optionalText}>OPTIONAL</p>
      </form>

      <p>Messaging & data rates may apply.</p>
    </article>
  );
}

SimpDownloadBox.displayName = 'SimpDownloadBox';

export { SimpDownloadBox };
