import React from 'react';

export const GLOBAL_CTX = React.createContext(null);
export const PAGE_CTX = React.createContext(null);

export const SMS_MSG = 'https://simplifimoney.com/getsimplifi';

export const LINKS = {
  MY_ACCOUNT: 'https://myaccount-stg.quickencs.com/account',
  SIMPLIFI: 'https://simplifimoney.com/',
  SIMP_APP: 'https://app.simplifimoney.com/', //https://app-stg.simplifimoney.com/login
  SIMP_SUPPORT: 'https://help.simplifimoney.com/en/',
  SIMP_TERMS: 'https://simplifimoney.com/terms',

  QKN_COMPARE: 'https://quicken.com/compare',
  FTR_SITEMAP: 'https://quicken.com/sitemap',
  FTR_LEGAL: 'https://quicken.com/legal',
  QKN_TERMS: 'https://quicken.com/terms-of-use',
  PRIVACY: 'https://quicken.com/privacy',
  FTR_ABOUT: 'https://quicken.com/about-us',
  QKN_REFUND: 'https://quicken.com/refund',
  QKN_SUPPORT: 'https://quicken.com/support',
  FTR_AGREEMENT: 'https://quicken.com/support/membership/agreement',
  EULA: 'https://quicken.com/support/eula',

  // APPLE_IOS_STORE: 'https://apps.apple.com/us/app/simplifi-budget-money-app/id1449777194',
  // GOOG_PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.quicken.acme',
  MOBILE_SWITCHER: 'https://simplifimoney.com/getsimplifi',
};

export const IMAGES = {
  SIMP_LOGO: 'https://www.simplifimoney.com/assets/images/simplifiLogo.svg',
  CC_CARDS: 'https://quicken.com/sites/all/modules/custom/quicken_checkout/img/icon-payment-all.png',
  CLOCK_ICON: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/icon-ar-reminder.png',
  CC_ICON: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/icon-ar-charge.png',
  CANCEL_ICON: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/icon-ar-cancel.png',
  ACTIVATION_HELP: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/svg/img-qkn-activatecard@2x.png',
  OFFER_BADGE: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/svg/badge-1mo-offer.svg',
  QKN_LOGO: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/svg/logo.svg',
  SUCCESS_ICON: 'https://quicken.com/sites/all/themes/custom/quicken_2014/images/svg/icon-notify-checkmark.svg',
  FAVICON_QKN: 'https://quicken.com/sites/all/themes/custom/quicken_2014/favicon.ico',
  FAVICON_SIMP: 'https://simplifimoney.com/assets/images/favicon.ico',
  PAYPAL_IMG: 'https://stage.quicken.com/sites/all/themes/custom/quicken_2014/images/svg/paypal.svg',
  DOT_GRID: 'https://www.simplifimoney.com/assets/images/simplifi_dotpat_square.svg'
};

export const ROUTES = {
  LOGOUT: '/logout', //remove all localstorage
  LOGIN_CALLBACK: '/login', // page iframe sends user to to get out of iframe
  GET_DATA: '/getData', // process signed in user

  QKN_LOGIN: '/quicken', //login iframe landing page
  QKN_DATA: '/quicken/getData', // process signed in user
  QKN_SUBSCRIBED: '/quicken/subscribed', // already subscribed
  QKN_ACTIVATE: '/quicken/activate', // enter code
  QKN_AUTO_RENEW: '/quicken/autoRenew', // payment forms
  QKN_PROFILE: '/quicken/profile', // profile page
  QKN_SUCCESS: '/quicken/success', // end page

  SIMP_LANDING: '/simplifi', // landing page
  SIMP_LOGIN: '/simplifi/login', //login iframe
  SIMP_DATA: '/simplifi/getData', // process signed in user
  SIMP_SUBSCRIBED: '/simplifi/subscribed', // already subscribed
  SIMP_UNAVAILABLE: '/simplifi/unavailable', // code is invalid
  SIMP_ACTIVATE: '/simplifi/activate', // enter code
  SIMP_AUTO_RENEW: '/simplifi/autoRenew', // payment forms
  SIMP_SUCCESS: '/simplifi/success' // end page
};
