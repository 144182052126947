import React from 'react';

import { pageReducer } from '../../../helpers/context/page-context';
import { PageProvider } from '../../../helpers/providers/page-provider';
import { QknActivateForm } from './qkn-activation-form';

const PAGE_INIT_STATE = {
  code: '',
  isValid: false,
  isDisabled: false,
  hasError: false,
  errObj: null,
};

const QknActivationFormCtx = (props) => {
  return (
    <PageProvider
      pageReducer={pageReducer}
      initState={PAGE_INIT_STATE}
    >
      <QknActivateForm {...props} />
    </PageProvider>
  );
};

QknActivationFormCtx.displayName = 'QknActivationFormCtx';

export { QknActivationFormCtx };
