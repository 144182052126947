import React from 'react';
import PropTypes from 'prop-types';

import { QknFooter } from './qkn-footer';
import { QknHeader } from './qkn-header';
import S from '../../sass/page-layout.module.scss';


function QknWrapper({children}) {
  return (
    <main className={S.pageLayout}>
      <QknHeader/>
      {children}
      <QknFooter/>
    </main>
  );
}

QknWrapper.displayName = 'QknWrapper';
QknWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export { QknWrapper };
