import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';


const BrainTreeField = ({id, className, label, error, errorClass}) => (
  <div key={id} className={className}>
    <label htmlFor={id}>{label}</label>
    <div id={id} className={classNames({hasError: error})}/>
    <p className={errorClass}>{error}</p>
  </div>
);

BrainTreeField.displayName = 'BrainTreeField';

BrainTreeField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  errorClass: PropTypes.string.isRequired
};

export { BrainTreeField };
