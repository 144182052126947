import React from 'react';

import { PAGE_CTX } from '../constants/constants';


export const PAGE_TYPES = {
  SET_INPUT_FIELD: 'SET_INPUT_FIELD',
  SET_PAGE_FIELD: 'SET_PAGE_FIELD',
  SET_PAGE_FIELDS: 'SET_PAGE_FIELDS',
  SET_PAGE_ERROR_FIELD: 'SET_PAGE_ERROR_FIELD',
};

export const setPageField = (field, payload = '') =>
  ({type: PAGE_TYPES.SET_PAGE_FIELD, field, payload});

export const setPageFields = (payload = {}) =>
  ({type: PAGE_TYPES.SET_PAGE_FIELDS, payload});

export const setPageErrorFields = (field, payload = '') =>
  ({type: PAGE_TYPES.SET_PAGE_ERROR_FIELD, field, payload});

export const setPageInputField = (field, payload = {}) =>
  ({type: PAGE_TYPES.SET_INPUT_FIELD, field, payload});

export function pageReducer(state, {type, field, payload}) {
  switch (type) {

    case PAGE_TYPES.SET_PAGE_FIELD:
      return {...state, [field]: payload};

    case PAGE_TYPES.SET_PAGE_FIELDS:
      return {...state, ...payload};

    case PAGE_TYPES.SET_PAGE_ERROR_FIELD:
      return {...state, errors: {...state.errors, [field]: payload}};

    case PAGE_TYPES.SET_INPUT_FIELD:
      return {
        ...state,
        form: {...state.form, [field]: payload.form},
        errors: {...state.errors, [field]: payload.error}
      };

    default:
      throw Error(`Unsupported action type: ${type}`);
  }
}

export const usePageCtx = () => {
  const context = React.useContext(PAGE_CTX);
  if (!context) {
    throw Error(`${usePageCtx.name} must be used within a ${pageReducer.name}`);
  }

  const [pageState, pageDispatch] = context;
  return {pageState, pageDispatch};
};
