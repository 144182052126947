import React from 'react';
import { SlideDown } from 'react-slidedown';

import { IMAGES } from '../../../helpers/constants/constants';
import S from './qkn-activation-help.module.scss';

const QknActivationHelp = () => {
  const [showHelp, setShowHelp] = React.useState(false);

  return (
    <>
      {!showHelp && (
        <button
          type="button"
          className={S.helpBtn}
          onClick={() => setShowHelp(true)}
        >Where is my 16-digit activation code?</button>
      )}
      <SlideDown closed={!showHelp} className={S.qknActivationHelp}>
        <p className={S.headingText}>Where to find your activation code</p>
        <div className={S.wrap}>
          <img src={IMAGES.ACTIVATION_HELP} alt="activation locations"/>
          <div>
            <p className={S.label}>If you purchased a retail box of Quicken</p>
            <p>
              Check inside the box your Quicken CD came in.
              You&apos;ll find an insert that has your activation code
            </p>

            <p className={S.label} >If you purchased Quicken online</p>
            <p>Your activation code will be included in your order confirmation email.</p>
          </div>
        </div>
        <button
          type="button"
          className={S.qknLinkBtn}
          onClick={() => setShowHelp(false)}
        >CLOSE HELP</button>
      </SlideDown>
    </>
  );
};

QknActivationHelp.displayName = 'QknActivationHelp';

export default QknActivationHelp;
