import React from 'react';
import { useHistory } from 'react-router';

import { ROUTES } from '../helpers/constants/constants';
import * as GlobalCtx from '../helpers/context/global-context';
import * as Calls from '../network/network-calls';


const GetUserData = () => {
  const { globalDispatch } = GlobalCtx.useGlobalCtx();
  const { push } = useHistory();

  const getUserData = React.useCallback(async () => {
    const PRODUCT = localStorage.getItem('product');
    const IS_SIMP = PRODUCT.toLowerCase() === 'simplifi';

    const [profile, subscriptions, payments] = await Promise.all([
      Calls.getUserProfile(),
      Calls.callGetSubscriptions(),
      Calls.callGetPaymentMethods()
    ]);

    const USER_ID = profile.data.userId;
    const CURRENT_SUB = subscriptions.data.find(({productLineUriName}) => productLineUriName === (IS_SIMP ? 'acme' : 'quicken-us'));
    const HAS_PAYMENT = !!payments.data.find(({defaultMethod}) => defaultMethod);

    globalDispatch(GlobalCtx.setGlobalFields({
      userId: USER_ID,
      profileId: profile.data.id,
      email: profile.data.primaryEmail.address,
      hasPayment: HAS_PAYMENT,
      name: {
        first: profile.data.firstName || '',
        last: profile.data.lastName || ''
      },
      address: {
        line1: profile.data?.primaryAddress?.line1 || '',
        line2: profile.data?.primaryAddress?.line2 || '',
        city: profile.data?.primaryAddress?.city || '',
        state: profile.data?.primaryAddress?.state || '',
        zipCode: profile.data?.primaryAddress?.zipCode || ''
      },
      meta: {
        hasData: true
      }
    }));

    if (!IS_SIMP) {
      push(CURRENT_SUB ? ROUTES.QKN_SUBSCRIBED : ROUTES.QKN_ACTIVATE);
    }
    else {
      if (CURRENT_SUB) {
        push(ROUTES.SIMP_SUBSCRIBED);
      }
      else {
        const ACTIVATION_CODE = localStorage.getItem('activation_code');

        if (ACTIVATION_CODE) {
          try {
            await Calls.checkActivationCode(ACTIVATION_CODE, USER_ID);
            globalDispatch(GlobalCtx.setGlobalFields({code: ACTIVATION_CODE}));
            push(ROUTES.SIMP_AUTO_RENEW);
          }
          catch (err) {
            console.log(err);
            push(ROUTES.SIMP_UNAVAILABLE);
          }
        }
        else {
          push(ROUTES.SIMP_ACTIVATE);
        }
      }
    }
  }, [globalDispatch, push]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return <div />;
};

export { GetUserData };
