import client from 'braintree-web/client';
import React from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';

import { PAYPAL_VALIDATIONS } from './simp-ar-root';
import S from './simp-ar.module.scss';
import { SimpCCForm } from './simp-cc-form';
import { PaymentLabel } from './simp-payment-label';
import { SimpPayPalForm } from './simp-paypal-form';
import { SimpWrapper } from '../simp-wrapper';
import { ActivationError } from '../../common/activation-error';
import * as brainTreeHelpers from '../../../helpers/braintree-helpers';
import { YEARLY_REGEX } from '../../../helpers/constants/regex';
import { IMAGES, ROUTES } from '../../../helpers/constants/constants';
import * as GlobalCtx from '../../../helpers/context/global-context';
import * as PageCtx from '../../../helpers/context/page-context';
import * as NetworkCalls from '../../../network/network-calls';
import { adjustedDate } from '../../../helpers/time-helpers';

const DATE_FORMAT = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};


const SimpArPage = ({history: {push}}) => {
  const [_hostedFields, setHostedFields] = React.useState(null);
  const {globalDispatch, globalState: {code, userId}} = GlobalCtx.useGlobalCtx();
  const {pageDispatch, pageState: {method, nonce, email, form, errObj, isDisabled}} = PageCtx.usePageCtx();

  const handleActivation = React.useCallback(async ({renewalSku, renewalFrequency}, method = null, paymentId = null) => {
    localStorage.removeItem('activation_code');

    const {data} = await NetworkCalls.callGetSimpPrice(renewalSku);
    const INTERVAL = YEARLY_REGEX.test(renewalFrequency) ? 'annually' : 'monthly';
    const RENEWAL_DATE = adjustedDate(renewalFrequency, DATE_FORMAT, true);
    const PRICE = data?.[renewalSku]?.productPrice ?? '';

    const DESCRIPTION_END = `will be billed ${PRICE ? `$${PRICE}` : ''} ${INTERVAL} starting on ${RENEWAL_DATE}`;

    globalDispatch(GlobalCtx.setGlobalField('simpSuccessObj', {
      lastDay: adjustedDate(renewalFrequency, DATE_FORMAT),
      description: method
        ? `After your free trial, your ${method === 'CREDIT' ? `card ending in ${paymentId}` : `PayPal account ${paymentId}`} ${DESCRIPTION_END}`
        : `After your free trial, you ${DESCRIPTION_END}`
    }));
    push(ROUTES.SIMP_SUCCESS);
  }, [globalDispatch, push]);

  const handleSkip = React.useCallback(async () => {
    try {
      pageDispatch(PageCtx.setPageFields({
        errObj: null,
        isDisabled: true
      }));

      const {data: {subscriptionResponse: {postState}}} = await NetworkCalls.callActivateCode({
        code,
        userId,
        autoRenew: false
      });

      await handleActivation(postState, null, null);
    }
    catch (err) {
      pageDispatch(PageCtx.setPageFields({
        errObj: err?.response?.data?.errors?.[0],
        isDisabled: false
      }));
    }
  }, [pageDispatch, handleActivation, code, userId]);

  const handleMethodSelection = React.useCallback(
    e => pageDispatch(PageCtx.setPageFields({method: e.target.value})),
    [pageDispatch]
  );

  const onPPSuccess = React.useCallback(async ({nonce, details: {email, billingAddress, firstName, lastName}}) => {
    pageDispatch(PageCtx.setPageErrorFields('nonce', ''));
    pageDispatch(PageCtx.setPageFields({
      nonce,
      email,
      form: {
        name: `${firstName} ${lastName}`,
        line1: billingAddress?.line1 || '',
        line2: billingAddress?.line2 || '',
        city: billingAddress?.city || '',
        zip: billingAddress?.postalCode || '',
        state: billingAddress?.state || '',
        country: billingAddress?.countryCode || ''
      }
    }));
  }, [pageDispatch]);


  const onCCSuccess = React.useCallback(async ({nonce, details: {lastFour}}) => {
    try {
      const [activateCall] = await Promise.all([
        NetworkCalls.callActivateCode({code, userId, autoRenew: true}),
        NetworkCalls.callUpdatePaymentNonce(nonce)
      ]);

      await handleActivation(
        activateCall.data.subscriptionResponse.postState,
        'CREDIT',
        lastFour || ''
      );
    }
    catch (err) {
      pageDispatch(PageCtx.setPageFields({
        errObj: err?.response?.data?.errors?.[0],
        isDisabled: false
      }));
    }
  }, [pageDispatch, handleActivation, code, userId]);

  const onSubmitFailure = React.useCallback(err => {
    console.log(err);

    err?.details?.invalidFieldKeys.forEach(field =>
      pageDispatch(PageCtx.setPageErrorFields(field, brainTreeHelpers.getErrorMsgs(field)))
    );

    pageDispatch(PageCtx.setPageFields({
      errObj: err?.response?.data?.errors?.[0] ?? null,
      isDisabled: false,
      isLoading: false
    }));
  }, [pageDispatch]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (method === 'credit') {
      brainTreeHelpers.handleSubmit(
        pageDispatch,
        _hostedFields,
        onCCSuccess,
        onSubmitFailure
      );
    }
    else {
      const ERROR_OBJ = {
        name: PAYPAL_VALIDATIONS.name(form.name),
        zip: PAYPAL_VALIDATIONS.zip(form.zip),
        nonce: nonce ? '' : 'Please click the PayPal button'
      };

      if (email && nonce && Object.values(ERROR_OBJ).every(val => val === '')) {
        try {
          const [activateCall] = await Promise.all([
            NetworkCalls.callActivateCode({code, userId, autoRenew: true}),
            NetworkCalls.callUpdatePaymentNonce(nonce),
            NetworkCalls.callUpdateAddress({
              fullName: form.name,
              line1: form.line1,
              line2: form.line2,
              city: form.city,
              state: form.state,
              country: form.country,
              zipCode: form.zip
            })
          ]);

          await handleActivation(
            activateCall.data.subscriptionResponse.postState,
            'PAYPAL',
            email || ''
          );
        }
        catch (err) {
          pageDispatch(PageCtx.setPageFields({
            errObj: err?.response?.data?.errors?.[0],
            isDisabled: false
          }));
        }
      }
      else {
        Object.keys(ERROR_OBJ).forEach(field =>
          pageDispatch(PageCtx.setPageErrorFields(field, ERROR_OBJ[field])));
      }
    }
  };


  const createHostedField = React.useCallback((clientErr, client) => {
    if (clientErr) {
      console.error('Error creating client:', clientErr);
      return;
    }

    brainTreeHelpers.initPayPal(client, onPPSuccess);
    brainTreeHelpers.initBrainTree(client, {
      name: '#simp-cc-name',
      number: '#simp-cc-number',
      cvv: '#simp-cvv',
      date: '#simp-cc-expires',
      postal: '#simp-cc-postal'
    }, brainTreeHelpers.initHostedFields.bind(null, pageDispatch, setHostedFields));
  }, [pageDispatch, onPPSuccess]);

  React.useEffect(() => {
    client.create(
      {authorization: process.env.REACT_APP_BRAINTREE_TOKEN},
      createHostedField
    );
  }, [createHostedField]);

  return (
    <SimpWrapper>
      <section className={S.autoRenewArea}>
        <h1 className={S.h1Heading}>Set up auto-renew for your account.</h1>

        <p className={S.line1}>Don’t worry, we won’t bill you until after your subscription expires.</p>
        <p className={S.line}>You can cancel your subscription at any time.</p>

        <button
          type="button"
          disabled={isDisabled}
          className={S.linkBtn}
          onClick={handleSkip}
        >Skip this step</button>

        <div className={S.paymentChoice}>
          <PaymentLabel
            value="credit"
            id="methodCC"
            alt="Valid credit card types are: Visa, Mastercard, American Express, and Discover."
            label="Credit / Debit Card"
            src={IMAGES.CC_CARDS}
            isChecked={method === 'credit'}
            onChange={handleMethodSelection}
          />
          <SlideDown closed={method !== 'credit'}>
            <SimpCCForm/>
          </SlideDown>

          <PaymentLabel
            value="paypal"
            id="methodPP"
            alt="PayPal Logo"
            src={IMAGES.PAYPAL_IMG}
            label="PayPal Checkout"
            isChecked={method === 'paypal'}
            onChange={handleMethodSelection}
          />
          <SlideDown closed={method !== 'paypal'}>
            <SimpPayPalForm/>
          </SlideDown>
        </div>

        {!!errObj && (
          <div className={S.errorMsg}>
            <ActivationError additionalClass={S.errorMsg} {...errObj} />
          </div>
        )}

        <button
          type="submit"
          disabled={isDisabled}
          className={S.submitBtn}
          onClick={handleSubmit}
        >Continue</button>

      </section>
    </SimpWrapper>
  );
};

SimpArPage.displayName = 'SimpArPage';
SimpArPage.propTypes = {
  history: PropTypes.object.isRequired
};

export { SimpArPage };
