import QS from 'query-string';
import React from 'react';

import * as S from './qkn-login.module.scss';
import { ROUTES } from '../../helpers/constants/constants';
import { QknWrapper } from './qkn-wrapper';

const LOGIN_PARAMS = QS.stringify({
  'hide-logo': true,
  response_type: 'code',
  client_id: process.env.REACT_APP_QKN_OAUTH_CLIENT_ID,
  redirect_uri: `${process.env.REACT_APP_SITE_URL}${ROUTES.LOGIN_CALLBACK}`,
});

function QuickenLogin({history: {push}}) {
  const URL = `${process.env.REACT_APP_SIGN_IN_URL}/signup?${LOGIN_PARAMS}`;
  React.useEffect(() => {
    const HAS_TOKEN = !!localStorage.getItem('bearer');
    localStorage.setItem('flow', 'UNKNOWN');
    localStorage.setItem('product', 'quicken');

    if (HAS_TOKEN) {
      push(ROUTES.GET_DATA);
    }
  }, [push]);

  return (
    <QknWrapper>
      <div className={S.qknLogin}>
        <iframe src={URL} title="Quicken Login"/>
      </div>
    </QknWrapper>
  );
}

QuickenLogin.displayName = 'QuickenLogin';

export { QuickenLogin };
