import React from 'react';

import S from './qkn-activation-terms.module.scss';
import { LINKS } from '../../../helpers/constants/constants';

const QknActivateTerms = () => {
  return (
    <p className={S.terms}>
      By clicking
      <span>activate membership</span>
      , I agree to the Quicken Inc.
      <a
        href={LINKS.QKN_TERMS}
        target="_blank"
        rel="noopener noreferrer"
        className={S.qknLinkBtn}
      >Terms of Use</a>
      and
      <a
        href={LINKS.PRIVACY}
        target="_blank"
        rel="noopener noreferrer"
        className={S.qknLinkBtn}
      >Privacy Policy.</a>
    </p>
  );
};

QknActivateTerms.displayName = 'QknActivateTerms';

export { QknActivateTerms };
