import PropTypes from 'prop-types';
import React from 'react';

import { PAGE_CTX } from '../constants/constants';

const PageProvider = (props) => {
  const [state, dispatch] = React.useReducer(props.pageReducer, props.initState);

  const value = React.useMemo(() => [state, dispatch], [state]);

  return (
    <PAGE_CTX.Provider value={value} {...props}>
      {props.children}
    </PAGE_CTX.Provider>
  );
};

PageProvider.displayName = 'PageProvider';
PageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  pageReducer: PropTypes.func.isRequired,
  initState: PropTypes.object.isRequired,
};

export { PageProvider };
