import React from 'react';
import PropTypes from 'prop-types';

import S from './activation-error.module.scss';
import { LINKS } from '../../helpers/constants/constants';


const ActivationError = ({code, extData}) => {

  switch (code) {

    case 'QCS-0400-8':
      return <p className={S.activationErr}>Sorry, this code has already been activated.</p>;

    case 'QCS-0401-2':
      return <p className={S.activationErr}>There was an authorization error, please sign in again.</p>;

    case 'CODE_UNRECOGNIZED':
      return (
        <p className={S.activationErr}>
          That is not a valid activation code.
          <br/>
          Please check your activation code and try again.
        </p>
      );

    case 'CODE_UNAVAILABLE':
      return (
        <p className={S.activationErr}>
          This code has already been redeemed Please
          <br/>
          <a href={LINKS.QKN_SUPPORT} className={S.linkBtn}>contact customer support</a>
          for more information.
        </p>
      );

    case 'CODE_EXPIRED':
      return (
        <p className={S.activationErr}>
          This code has expired Please
          <br/>
          <a href={LINKS.QKN_SUPPORT} className={S.linkBtn}>contact customer support</a>
          for more information.
        </p>
      );

    case 'CODE_ALREADY_CONSUMED_BY_USER':
      return (
        <p className={S.activationErr}>
          You have already redeemed this code. Please
          <br/>
          <a href={LINKS.QKN_SUPPORT} className={S.linkBtn}>contact customer support</a>
          for more information.
        </p>
      );

    case 'ACTIVATION_VIOLATES_SUBSCRIPTION_RULES':
      if (extData?.consumableOn) {
        const DATE = new Date(extData.consumableOn).toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return <p className={S.activationErr}>Please wait until {DATE} to redeem this activation code.</p>;
      }
      else {
        return (
          <p className={S.activationErr}>
            You recently went through an upgrade of membership.
            <br/>
            Please come back later to upgrade again
          </p>
        );
      }
    case 'ALLOWED_FOR_NEW_USER_ONLY':
      return (
        <p className={S.activation2Err}>
          The product you purchased is for New Subscribers only, and you are an existing subscriber. You can request a
          refund for this product by going
          <a href={LINKS.QKN_REFUND} className={S.linkBtn}>here</a>
          . In your Quicken software, go to the help menu and select My
          Quicken Account to review your renewal options.
        </p>
      );

    case 'SUBSCRIPTION_CREATION_FAILED':
      return <p className={S.activationErr}>The call to the subscription service failed, please try again later.</p>;

    default:
      return <p className={S.activationErr}>An unknown error occurred please try again.</p>;
  }
};

ActivationError.displayName = 'QknActivationError';
ActivationError.propTypes = {
  code: PropTypes.string.isRequired,
  extData: PropTypes.object
};

export { ActivationError };
