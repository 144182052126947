import React from 'react';

import { QknProfile } from './qkn-profile';
import { useGlobalCtx } from '../../../helpers/context/global-context';
import { pageReducer } from '../../../helpers/context/page-context';
import { PageProvider } from '../../../helpers/providers/page-provider';

const PROFILE_PAGE_INIT_STATE = (globalState) => ({
  isDisabled: false,

  form: {
    retailer: '',
    experience: globalState.address.line1 ? 'Existing' : 'New',
    firstName: globalState.name.first || '',
    lastName: globalState.name.last || '',
    line1: globalState.address.line1 || '',
    line2: globalState.address.line2 || '',
    city: globalState.address.city || '',
    state: globalState.address.state || '',
    zipCode: globalState.address.zipCode || ''
  },

  errors: {
    retailer: '',
    experience: '',
    firstName: '',
    lastName: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: ''
  }
});

const QknProfileFlow = (props) => {
  const {globalState} = useGlobalCtx();
  return (
    <PageProvider
      pageReducer={pageReducer}
      initState={PROFILE_PAGE_INIT_STATE(globalState)}
    >
      <QknProfile {...props} />
    </PageProvider>
  );
};

export { QknProfileFlow };
